.news {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media('screen', '>=widescreen') {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    & + .info-block {

        @include media('screen', '>=tablet') {
            margin-top: -100px;
        }

        @include media('screen', '>=widescreen') {
            margin-top: -235px;
        }
    }

    &-item {
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 4px solid $gray-100;

        &:hover {
            .news-item-meta {
                background-color: $primary;
                color: $white;
            }
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;

            @include media('screen', '>=widescreen') {
                margin-bottom: 40px;
            }
        }

        @include media('screen', '>=tablet') {
            display: flex;
        }

        @include media('screen', '>=widescreen') {
            padding-bottom: 110px;
            margin-bottom: 110px;
        }

        &-meta {
            flex-grow: 0;
            flex-shrink: 0;
            width: 60px;
            height: 60px;
            padding-top: 13px;
            margin-bottom: 30px;
            color: $primary;
            text-align: center;
            background-color: $gray-100;
            border-radius: 50%;
            transition: background-color .3s ease, color .3s ease;

            @include media('screen', '>=tablet') {
                width: 96px;
                height: 96px;
                margin-right: 27px;
            }
        }

        &-month {
            display: block;
            font-size: 14px;
            font-weight: 900;
            line-height: 1;

            @include media('screen', '>=tablet') {
                font-size: 15px;
            }
        }

        &-date {
            display: block;
            margin-top: -3px;
            font-size: 30px;
            font-family: $font-family-serif;
            font-weight: 700;
            line-height: 1;

            @include media('screen', '>=tablet') {
                font-size: 61px;
            }
        }

        &-body {
            flex: 1 1 100%;
            margin-top: -3px;
        }

        &-title {
            margin-bottom: 10px;
        }

        &-text {
            margin-bottom: 27px;
        }
    }
}