.link {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;

    @include media('screen', '>=desktop') {
        font-size: 15px;
    }
}

.icon-arrow-right {
    &:before {
        display: inline-block;
        vertical-align: middle;
        width: 0.75em;
        height: 0.75em;
        border: 3px solid currentColor;
        border-width: 3px 3px 0 0;
        transform: rotate(45deg);
        content: '';
    }
}

.icon-double-arrow-right {
    &:before,
    &:after {
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: 13px;
        border: 3px solid;
        border-color: currentColor currentColor transparent transparent;
        transform: rotate(45deg);
        content: '';
    }
}

.course-catalog-box {
    position: relative;
    padding-top: 126px;

    &:before {
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        width: 100vw;
        background-color: $secondary;
        content: '';
    }

     .box {
        @include media('screen', '>=tablet') {
            display: flex;
            justify-content: space-between;
        }

        @include media('screen', '>=laptop') {
            padding: 120px 125px;
        }

        .col-left {
            @include media('screen', '>=tablet') {
                flex: 0 1 432px;
                margin-right: 30px;
            }
        }

        .col-right {
            @include media('screen', '>=tablet') {
                flex: 0 1 524px;
            }
        }

        .image {
            position: relative;
            display: block;
            padding-top: 78.6%;
            margin-bottom: 30px;
            border-radius: 14px;
            overflow: hidden;

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 1s ease;
            }
        }

        h2 {
            margin-bottom: 23px;
        }
    }
}

.time-to-complite {
    width: 100%;
    padding: 30px;
    border-radius: 10px;

    @include media('screen', '>=laptop') {
        padding: 62px 73px;
    }

    &-title {
        @include media('screen', '>=widescreen') {
            margin-bottom: 63px;
        }
    }

    &-list {
        @extend %listreset;

        @include media('screen', '>=tablet') {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
        }

        > * {
            @include media('screen', '>=tablet') {
                flex: 1 1 50%;
                max-width: 290px;
                padding-left: 15px;
                padding-right: 15px;
            }

            @include media('screen', '>=desktop') {
                flex: 1 1 33.33333%;
                max-width: 290px;
            }
        }
    }

    &-item {
        margin-bottom: 30px;

        &-icon {
            width: 156px;
            height: 156px;
            padding: 20px;
            margin-bottom: 28px;
            background-color: $white;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-title {
            display: block;
            margin-bottom: 10px;
            font-weight: 900;
        }

        &-text {
            margin-bottom: 0;
        }
    }
}

.academic-programs {
    &.alternative {
        padding-top: 50px;
        padding-bottom: 50px;

        .wrap {
            background-color: transparent;
        }

        .container {
            padding-left: 15px;
            padding-right: 15px;
        }

        .academic-programs-inner {
            padding-left: 30px;
            padding-right: 30px;

            @include media('screen', '>=desktop') {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .academic-programs-header {
            margin-bottom: 23px;

            @include media('screen', '>=phone-lg') {
                display: none;
            }
        }

        .academic-programs-footer {
            padding-top: 17px;
            padding-bottom: 17px;
            margin-left: -13px;
            margin-right: -13px;
            color: $secondary;
            line-height: 1.45;

            @include media('screen', '>=phone-lg') {
                display: none;
            }
        }

        .btn-mobile-lg {
            @include media('screen', '<phone-lg') {
                padding: 17px 26px;
            }
        }
    }
}

.program-slider {
    &.alternative {
        padding: 0;
        margin-bottom: 0;

        @include media('screen', '>=desktop') {
            margin-bottom: 0;
        }

        .slick-arrow {
            &.slick-prev {
                left: -46px;
            }

            &.slick-next {
                right: -46px;
            }
        }

        .chevrons {
            margin-left: 0;

            @include media('screen', '>=desktop') {
                margin-left: -1px;
            }
        }
    }
}

.mission {
    &.alternative {
        position: relative;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            bottom: 0;
            z-index: -1;
            width: 100vw;
            transform: translateX(-50%);
            background-color: $secondary;
            content: '';
        }
    }
}

.mission-box {
    &-illustration {
        .alternative & {
            padding-top: 93%;

            @include media('screen', '>=desktop') {
                padding-top: 0;
            }
        }

        img {
            .alternative & {
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(100% + 50px);
                max-width: none;

                @include media('screen', '>=laptop') {
                    left: auto;
                }
            }
        }
    }
}

.mission-box-smaller {
    .alternative & {
        @include media('screen', '>=desktop') {
            margin-bottom: 0;
        }
    }

  .mission-box-pattern {
    height: 100%;
    width: 100%;

    max-height: 450px;

    @include media('screen', '>=desktop') {
      max-height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.counselor {

    &-title {
        h3 {
            .bg-secondary & {
                color: inherit;
            }
        }
    }

    &-decors {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 35.4%;
        padding-top: 32%;

        @include media('screen', '<desktop') {
            display: none;
        }
    }

    &-decor {
        position: absolute;
        right: 0;
        width: 100%;

        &-1 {
            bottom: 0;
            z-index: 2;
            width: 100%;
        }

        &-2 {
            bottom: 21.6%;
            z-index: 2;
            width: 60%;
        }

        &-3 {
            bottom: 21%;
            z-index: 0;
            width: 34%;
        }
    }

    &-photo {
        position: relative;
        z-index: 1;
    }
}

.counselor + .more-success-stories {
    padding-top: 70px;

    @include media('screen', '>=laptop') {
        padding-top: 265px;
    }
}
