// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  @Example usage of font-family mixin;
//
//  $font-primary: (
//    'Light': $font-weight-light,
//    'Regular': $font-weight-normal,
//  );
//
//  $font-secondary: (
//    'Thin': $font-weight-thin,
//    'Bold': $font-weight-bold,
//  );
//
//  @param {
//    $fontName: Filename: string;
//    $fontWeight: 100|300|400|normal|bold;
//    $fontStyle: normal/italic/etc;
//  }
//
//  // Filename: Base-Regular.woff
//  @each $suffix, $weight in $font-primary {
//    @include fontface('Base', 'Base-#{$suffix}', $weight, normal);
//  }
//
//  @each $suffix, $weight in $font-secondary {
//    @include fontface('Second Font', '${LocalFontName}-#{$suffix}', $weight, normal);
//    @include fontface('Second Font', '${LocalFontName}-#{$suffix}Italic', $weight, italic);
//  }
// -----------------------------------------------------------------------------

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 7, 2022 */

@font-face {
  font-family: 'impact';
  src: url('../fonts/impact-webfont.woff2') format('woff2'), url('../fonts/impact-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-black-webfont.woff2') format('woff2'), url('../fonts/raleway-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-bold-webfont.woff2') format('woff2'), url('../fonts/raleway-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-extrabold-webfont.woff2') format('woff2'), url('../fonts/raleway-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-medium-webfont.woff2') format('woff2'), url('../fonts/raleway-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-regular-webfont.woff2') format('woff2'), url('../fonts/raleway-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
      url('../fonts/MyriadPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
      url('../fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orpheus Pro';
  src: url('../fonts/orpheuspro-bold-webfont.woff2') format('woff2'),
       url('../fonts/orpheuspro-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal
}