.table-responsive {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    margin-bottom: $paragraph-margin-bottom;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
        th {
            padding: 16px 20px;
            color: $white;
            background-color: $primary;
        }
    }

    tbody {
        td, th {
            padding: 22px 20px;
            border-bottom: 2px solid $gray-100;
        }

        th {
            color: $secondary;
        }

        tr {
            &:hover {
                background-color: $gray-100;
            }
        }
    }    

    th {
        text-align: left;
    }

    th, td {
        &:first-child {
            border-radius: 2px 0 0 2px;
        }

        &:last-child {
            border-radius: 0 2px 2px 0;
        }
    }
}