// -----------------------------------------------------------------------------
// This file contains all styles related to the Slick slider component.
// -----------------------------------------------------------------------------

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slide .slick-track,
.slick-slide .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow {
	@include size(32px);
	@include animate(opacity);
	position: absolute;
	top: 80px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	color: #8da2ad;

	@include media('>=desktop') {
		top: 50%;
		transform: translateY(-60%);
	}

	@include media('>=laptop') {
		@include size(40px);
		transform: translateY(-80px);
	}

	&:hover {
		opacity: .5;
	}

	&.slick-prev {
		left: 0;
	}

	&.slick-next {
		right: 0;
	}

	.icon-arrow-right,
	.icon-arrow-left {
		display: inline-block;
		vertical-align: middle;
		width: 0.75em;
		height: 0.75em;
		border: 3px solid currentColor;
		transform: rotate(45deg);

		&:before {
			display: none;
		}
	}

	.icon-arrow-right {
		border-width: 3px 3px 0 0;
	}

	.icon-arrow-left {
		border-width: 0 0 3px 3px;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-dots {
	@extend .listreset;
	display: flex;
	width: 100%;
	margin: 0 -6px;

	li {
		padding: 0 6px;

		&.slick-active,
		&:hover {

			button {
				background: $primary;
			}
		}
	}

	button {
		@include size(10px);
		@include animate(background);
		@extend .hide-text;
		padding: 0;
		border: none;
		border-radius: 50%;
		background: #8da2ad;
		cursor: pointer;
	}
}

.rows-gallery {
	padding: 0 30px 30px;

	@include media('>=laptop') {
		padding: 0 60px 50px 0;
	}

	.slick-slide {

		> div {

			@include media('>=desktop') {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.slick-prev.slick-prev {

		@include media('>=tablet') {
			left: -60px;
		}
	}

	.slick-next.slick-next {

		@include media('>=tablet') {
			// right: -60px;
		}

		@include media('>=widescreen') {
			right: 0;
		}
	}

	.serv-slide {

		// @include media('>=tablet') {
		// 	padding: 0 50px 0 0;
		// }

		@include media('>=desktop') {
			padding: 0 100px 0 0;
		}

		@include media('>=widescreen') {
			padding: 0 200px 0 0;
		}
	}
}

.rows-gallery-alt {
	padding: 0 0 30px;

	@include media('>=laptop') {
		padding: 0 0 50px;
	}

	.slick-slide {

		> div {

			@include media('>=desktop') {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	
	.slick-prev.slick-prev {

		@include media('>=tablet') {
			left: -60px;
		}
	}

	.slick-next.slick-next {

		@include media('>=tablet') {
			right: -60px;
		}
	}
}

