.info-block {
  position: relative;
  pointer-events: none;

  &-decors {
      position: relative;
      padding-top: 35.3%;
  }

  &-decor {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
  }

  &-content {
      position: relative;
      z-index: 1;
      padding-top: 30px;
      padding-bottom: 40px;
      pointer-events: auto;

      &:before {
          position: absolute;
          top: -2px;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100px;
          background-color: $primary;
          transform: skewY(-2deg);
          transform-origin: 0 0;
          content: '';
      }
  }

  &-inner {
      @include media('screen', '>=tablet') {
          display: flex;
          margin-left: -15px;
          margin-right: -5px;
      }
  }

  &-col {
      &-left {
          @include media('screen', '>=tablet') {
              flex: 1 1 65%;
              margin-top: -3.2%;
              padding-left: 15px;
              padding-right: 15px;
          }
      }

      &-right {
          display: none;

          @include media('screen', '>=tablet') {
              flex: 1 1 35%;
              display: block;
              margin-top: -9%;
              padding-left: 15px;
              padding-right: 15px;
          }
      }
  }

  &-img {
      @include media('screen', '>=tablet') {
          width: 250px;
          height: 250px;
          border-radius: 50%;
          overflow: hidden;
      }

      @include media('screen', '>=widescreen') {
          width: 382px;
          height: 382px;
      }

      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }

  &-title {
      @include media('screen', '>=widescreen') {
          display: inline;
      }
  }

  &-btn {
      @include media('screen', '>=widescreen') {
          position: relative;
          top: -11px;
          display: inline;
          white-space: nowrap;
          width: auto;
          margin-left: 20px;
      }
  }
}