.mejs {
    &__mediaelement {
        position: relative;
        height: auto;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            background: linear-gradient(180deg, rgba($black,.7) 0%, rgba($black,.3) 20%, rgba($black,0.3) 80%, rgba($black,.7) 100%);
            content: '';
        }
    

        > * {
            display: block;
            width: 100%;
        }

        video {
            width: 100% !important;
            height: auto !important;
            vertical-align: top;
        }
    }

    &__container {
        width: 100% !important;
        height: auto !important;
        min-width: 0 !important;
        margin-bottom: 40px;
        font-family: $font-family-sans-serif;
    }

    &__controls {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: auto;
        padding: 10px;

        @include media('screen', '>=tablet')  {
            padding: 33px 29px;
        }
    }

    &__button,
    &__time,
    &__time-rail {
        width: auto;
        height: auto;
    }

    &__button {
        > button {
            @extend .hide-text;
            position: relative;
            padding: 0;
            background-color: transparent;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border: none;
            cursor: pointer;
            
            &:hover {
                opacity: .8;
            }
        }
    }

    &__playpause-button {
        order: 0;
        margin-right: 27px;

        > button {
            width: 23px;
            height: 23px;
            margin: 0;
        }
    }

    &__play {
        > button {
            background-image: url(../images/_icon-play.svg);
        }
    }

    &__replay {
        > button {
            background-image: url(../images/_icon-replay.svg);
        }
    }

    &__pause {
        > button {
            background-image: url(../images/_icon-pause.svg);
        }
    }

    &__time {
        height: auto;
        padding: 0;
        font-size: 21px;
        line-height: 1;
        font-weight: 500;
        text-align: left;
        box-sizing: border-box;
    }

    &__currenttime-container {
        order: 2;

        &:after {
            content: '/';
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    &__duration-container {
        order: 3;
    }

    &__volume-button {
        order: 1;
        margin-right: 29px;

        > button {
            width: 31px;
            height: 25px;
            margin: 0;
            
        }
    } 

    &__mute {
        > button {
            background-image: url(../images/_icon-volume.svg);
        }
    }

    &__unmute {
        > button {
            background-image: url(../images/_icon-volume.svg);

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 150%;
                height: 5px;
                background-color: $primary;
                border-radius: 1px;
                content: '';
            }
        }
    }

    &__fullscreen-button {
        order: 4;
        margin-left: auto;

        > button {
            margin: 0;
            width: 45px;
            height: 45px;
            background-image: url(../images/_icon-fullscreen.svg);
        }
    }

    &__time-rail {
        flex: 1 1 100%;
        order: 5;
        height: 7px;
        margin: 12px 0 0;
        padding: 0;
    }

    &__time-total {
        margin: 0;
        background-color: rgba($white, .5);
    }

    &__time-total,
    &__time-buffering,
    &__time-loaded,
    &__time-current,
    &__time-float,
    &__time-hovered,
    &__time-float-current,
    &__time-float-corner,
    &__time-marker {
        height: 100%;
        border-radius: 3px;
    }

    &__time-handle {
        &-content {
            top: -8px;
            width: 18px;
            height: 18px;
            background-color: $primary;
            border: 1px solid $white;
            border-radius: 50%;
            transform: scale(1);
        }
    }

    &__time-float {
        display: none !important;
    }

    &__time-current {
        background-color: transparent;
    }

    &__layers {
        display: none;
    }
}

.custom-video {
    position: relative;

    &-share {
        position: absolute;
        top: 24px;
        right: 22px;
        z-index: 2;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
}