.widget-contact {
    position: relative;
    padding: 13px 22px 18px;
    margin-bottom: 40px;
    border-radius: 8px;
    background-color: $gray-100;
    color: $secondary;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        width: 44px;
        height: 44px;
        background: $primary url(../images/_icon-phone-2.svg) no-repeat 50% 50%;
        background-size: 24px auto;
        border-radius: 0 10px;
        content: '';
    }

    &-title {
        margin-bottom: 30px;
    }
    
    &-block {
        margin-bottom: 30px;
        font-family: $font-family-sans-serif-tertiary;
        line-height: 1.45;
        color: $secondary;

        &:last-of-type {
            margin-bottom: 0;
        }

        &-title {
            margin-bottom: 5px;
            font-weight: 700;
            font-family: inherit;
        }

        &-list {
            @extend %listreset;
            margin-bottom: 0;

            a {
                font-weight: 400;
                color: $primary;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.widget-info {
    padding: 20px 25px 14px;
    margin-bottom: 40px;
    border-radius: 8px;

    &-title {
        font-family: $font-family-sans-serif-tertiary;
    }

    &-text {
        margin-bottom: 8px;
        font-size: 15px;
        line-height: 1.5;
    }
}