.components {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media('screen', '>=widescreen') {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &-header {
        margin-bottom: 50px;

        @include media('screen', '>=desktop') {
            margin-left: 17.4%;
            margin-bottom: 92px;
        }
    }

    &-typography {
        max-width: 586px;
        width: 100%;
    }
}