.scroll-to-top {
    position: fixed;
    bottom: 35px;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: $primary;
    border-radius: 10px;
    color: $white;
    box-shadow: 0 0 5px rgba($secondary, .5);
    transition: background-color .3s ease, color, .3s ease, opacity .3s ease, visibility .3s ease;

    &:hover {
        background-color: $white;
        color: $primary;
    }

    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}