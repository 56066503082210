.contact {
    padding-top: 48px;
    padding-bottom: 48px;

    &-header {
        margin-bottom: 42px;

        @include media('screen', '>=tablet') {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
        }

        @include media('screen', '>=widescreen') {
            margin-left: -40px;
            margin-right: -40px;
        }

        &-col {
            @include media('screen', '>=tablet') {
                flex: 1 1 50%;
                max-width: 50%;
                padding-left: 15px;
                padding-right: 15px;
            }

            @include media('screen', '>=widescreen') {
                padding-left: 40px;
                padding-right: 40px;
            }

            &-right {
                @include media('screen', '>=tablet') {
                    padding-top: 10px;
                }
            }
        }
    }

    &-address {
        margin-bottom: 35px;
        font-style: normal;

        &-title {
            margin-bottom: 4px;
        }
    }

    &-title {
        margin-bottom: 18px;
    }

    &-link {
        color: $primary;

        &:hover {
            text-decoration: underline;
        }
    }

    &-form {
        &-inner {
            @include media('screen', '>=desktop') {
                display: flex;
                margin-left: -18px;
                margin-right: -18px;
            }
        }

        &-col {
            @include media('screen', '>=desktop') {
                flex: 1 1 50%;
                max-width: 50%;
                padding-left: 18px;
                padding-right: 18px;
            }
        }

        button[type="submit"] {
            margin-top: 10px;
        }
    }
}