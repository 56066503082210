.container {
    max-width: 1410px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;

    &:after {
        display: block;
        clear: both;
        content: '';
    }

    &-inner {
        @include media('screen', '>=tablet') {
            margin-left: 8.3333333%;
        }

        @include media('screen', '>=widescreen') {
            margin-left: 16.6667%;
        }
    }
}