// -----------------------------------------------------------------------------
// This file contains all unique styles for home page only
// -----------------------------------------------------------------------------

.hero-section {
  padding: 40px 0 0;
  position: relative;

  @include media('screen', '>=desktop') {
    padding-top: 80px;
  }

  .decor {
    position: relative;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      right: 0;
      background: url(../images/hero-decor.png);
      background-size: 206% auto;
      background-position: 93% 100%;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;

      @include media('screen', '>=tablet') {
        width: 1258px;
        height: 510px;
        bottom: -12px;
        background-size: 100% 100%;
      }
    }
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      display: flex;
      flex-wrap: wrap;
      max-width: 1373px;
      padding: 0 15px;
    }
  }

  .tilted-text {
    position: absolute;
    left: 4px;
    top: 362px;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $gray-200;
    letter-spacing: 0.03em;

    @include media('screen', '>=desktop') {
      left: 25px;
      top: 150px;
    }

    @include media('screen', '>=laptop') {
      left: 51px;
      top: 350px;
    }

    span {
      position: absolute;
      white-space: nowrap;
    }

    .chevrons {
      margin-left: 11px;
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      transform: rotate(-45deg);
    }
  }

  .text-holder {
    font-size: 21px;
    line-height: 1.5238095238095237;
    position: relative;
    z-index: 2;
    margin-bottom: 33px;

    @include media('screen', '>=desktop') {
      padding-left: 75px;
      padding-bottom: 300px;
      width: calc(100% - 513px);
    }

    @include media('screen', '>=laptop') {
      padding-left: 226px;
    }
  }

  h1 {
    margin-bottom: 30px;
    letter-spacing: -0.03em;

    @include media('screen', '>=desktop') {
      margin-bottom: 10px;
    }
  }

  .hide-mobile {
    display: none;
    line-height: 1.6;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }

  p {
    margin-bottom: 39px;
  }

  .btn {
    width: 100%;
    font-size: 23px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 19px 60px 18px 28px;

    @include media('screen', '>=desktop') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 2px;
      text-align: center;
      padding: 9px 44px 9px 17px;
    }

    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }

  .img-holder {
    position: relative;
    right: 5px;

    @include media('screen', '>=desktop') {
      width: 513px;
      right: 17px;
      top: 4px;
    }

    .decor-circle {
      position: absolute;
      top: 22px;
      left: 41px;
      z-index: -1;
    }
  }

  + .college-promise {
    position: relative;
    z-index: 3;
    margin-top: -169px;

    @include media('screen', '>=desktop') {
      margin-top: -154px;
    }
  }
}

.college-promise {
  background: $white;
  border-radius: 4px;
  color: $gray-300;
  font-size: 21px;
  line-height: 1.4285714285714286;
  padding: 39px 0;
  position: relative;

  @include media('screen', '>=desktop') {
    border-radius: 4px;
    padding: 0;
    background: transparent;
    line-height: 1.619047619047619;
  }

  .decor {
    display: none;
    position: absolute;
    bottom: 363px;
    left: 0;
    background: url(../images/decor-1.png);
    width: 436px;
    height: 259px;
    z-index: 1;

    @include media('screen', '>=laptop') {
      display: block;
    }
  }

  .box {

    @include media('screen', '>=desktop') {
      padding-top: 106px;
      padding-bottom: 87px;
    }
  }

  .top-block {
    color: $brown;

    @include media('screen', '>=desktop') {
      padding-left: 50px;
      padding-right: 50px;
    }

    @include media('screen', '>=laptop') {
      padding-left: 228px;
    }
  }

  h1 {
    font-size: 30px;
    color: $gray-300;
    font-weight: 400;
    font-family: $font-family-sans-serif-secondary;
    text-transform: capitalize;

    @include media('screen', '>=desktop') {
      font-family: $font-family-serif;
      font-weight: 600;
      font-size: 75px;
      color: $brown;
      margin-bottom: 24px;
      position: relative;
      left: -10px;
    }
  }

  strong {
    display: block;
    font-weight: 400;
    margin-bottom: 40px;

    @include media('screen', '>=desktop') {
      font-weight: 900;
      font-size: 22px;
      line-height: 1.2727272727272727;
      margin-bottom: 45px;
    }
  }

  .hide-mobile {
    display: none;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }

  .btn-holder {
    @include media('screen', '>=desktop') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 23px 0 85px;
    }
  }

  .btn {
    width: 100%;
    font-size: 24px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 19px 60px 18px 28px;

    @include media('screen', '>=desktop') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 2px;
      padding: 8px 50px 8px 19px;
      letter-spacing: 0.035em;
    }

    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }

  .bottom-block {
    display: none;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }

  .numbers-list {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;

    li {
      width: 21%;
      padding: 0 10px;
    }

    .number {
      display: block;
      font-size: 100px;
      font-weight: 500;
      color: $red;
      margin-bottom: -2px;
      font-family: $font-family-serif;

      @include media('screen', '>=laptop') {
        font-size: 165px;
      }
    }

    .text {
      display: block;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.3;
      color: $brown;

      @include media('screen', '>=laptop') {
        font-size: 22px;
      }
    }
  }
}

.start-journey {
  position: relative;
  display: none;

  @include media('screen', '>=desktop') {
    display: block;
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .img-block {
    height: 400px;
    border-radius: 14px;
    overflow: hidden;

    @include media('screen', '>=desktop') {
      position: absolute;
      top: -230px;
      right: 50%;
      width: 50vw;
      bottom: 0;
      height: auto;
      border-radius: 0;
      overflow: visible;
    }

    img {
      @include media('screen', '>=desktop') {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 14px 14px 0;
      }
    }
  }

  .text-block {
    padding: 40px 0 20px;

    @include media('screen', '>=desktop') {
      padding-left: calc(50% + 83px);
      padding-top: 78px;
      padding-bottom: 164px;
      padding-right: 60px;
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 61px;
  }

  .btn {
    width: 100%;
    font-size: 23px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 19px 60px 18px 28px;
    letter-spacing: 0.04em;

    @include media('screen', '>=desktop') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 2px;
      text-align: center;
      padding: 9px 46px 9px 17px;
    }

    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }
}

.who-are-you {
  position: relative;
  padding: 74px 0 62px;

  @include media('screen', '>=desktop') {
    padding-top: 151px;
    padding-bottom: 72px;
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .tilted-text {
    display: none;
    position: absolute;
    right: 4px;
    top: 0;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $gray-200;
    letter-spacing: 0.03em;

    @include media('screen', '>=desktop') {
      right: 15px;
      top: 0;
      display: block;
    }

    @include media('screen', '>=laptop') {
      right: 47px;
    }

    span {
      position: absolute;
      white-space: nowrap;
    }

    .chevrons {
      margin-left: 11px;
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      transform: rotate(-45deg);
    }
  }

  h2 {
    text-align: left;
    color: $brown;
    margin-bottom: 38px;

    @include media('screen', '>=desktop') {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .dropdown {
    position: relative;
    z-index: 2;
    margin-bottom: 20px;

    &.open {
      .list {
        display: block;

        @include media('screen', '>=desktop') {
          display: flex;
        }
      }
    }

    .caption {
      display: block;
      font-weight: 900;
      cursor: pointer;
      background: #e3e3e3;
      padding: 17px 40px 17px 28px;
      font-size: 24px;
      line-height: 1.25;
      color: $red;
      border-radius: 8px;
      letter-spacing: 0.03em;

      @include media('screen', '>=desktop') {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%) rotate(-135deg);
        height: 22px;
        width: 22px;
        border: solid #9db2bb;
        border-width: 0 0 3px 3px;
        @include transition(border-color);

        @include media('screen', '>=desktop') {
          height: 13px;
          width: 13px;
          right: 17px;
        }
      }
    }

    .list {
      position: absolute;
      width: 100%;
      top: 0;
      display: none;
      background: $white;
      border-radius: 8px;
      overflow: hidden;

      @include media('screen', '>=desktop') {
        display: flex;
        position: relative;
        background: transparent;
        width: auto;
      }
    }

    .item {
      padding: 17px 28px;
      cursor: pointer;

      @include media('screen', '>=desktop') {
        padding: 10px 21px;
      }

      &:hover {
        background: #e2e2e2;
      }
    }
  }

  .nav-select {
    @extend %listreset;

    @include media('screen', '>=desktop') {
      display: flex;
      justify-content: center;
      margin-bottom: 37px;
      padding: 0 40px;
    }

    @include media('screen', '>=laptop') {
      padding: 0;
    }

    li {
      @include media('screen', '>=desktop') {
        margin: 0 6px 12px;
      }

      &.active {
        a {
          color: $red;
          background: #e2e2e2;
        }
      }
    }

    a {
      display: block;
      font-size: 24px;
      line-height: 1.25;
      font-weight: 900;
      color: $gray-300;
      padding: 17px 28px;
      text-decoration: none;
      letter-spacing: 0.03em;
      text-align: left;
      @include transition(background color);

      @include media('screen', '>=desktop') {
        text-align: center;
        border-radius: 4px;
        font-size: 18px;
        line-height: 1.3636363636363635;
        padding: 10px 21px;
      }

      @include media('screen', '>=laptop') {
        font-size: 22px;
      }

      &:hover {
        color: $red;
        background: #e2e2e2;
      }
    }
  }
}

.features-list {
  @extend %listreset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -9px;

  @include media('screen', '>=desktop') {
    padding: 0 20px;
    margin: 0;
  }

  li {
    max-width: 112px;
    margin: 0 9px 29px;

    @include media('screen', '>=desktop') {
      width: 33.33%;
      max-width: none;
      margin: 0;
      padding: 0 20px 40px;
    }
  }

  a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    color: $brown;
    font-size: 13px;
    line-height: 1.3846153846153846;
    font-family: $font-family-sans-serif-secondary;
    @include animate(color background);
    border-radius: 8px;
    text-decoration: none;
    text-align: center;

    @include media('screen', '>=desktop') {
      font-size: 25px;
      line-height: 1.2666666666666666;
      background: #e2e2e2;
      flex-direction: row;
      align-items: center;
      padding: 9px 10px 9px 10px;
    }

    @include media('screen', '>=laptop') {
      font-size: 30px;
      padding: 9px 24px 9px 14px;
    }

    &:hover {
      .img-holder {
        background: #a4123f;

        @include media('screen', '>=desktop') {
          background: transparent;
        }
      }

      @include media('screen', '>=desktop') {
        background: #a4123f;
        color: $white;
      }

      .text.my-compton {
        .my {
          @include media('screen', '>=desktop') {
            background: url(../images/text-my-white.png);
          }
        }
      }
    }
  }

  .text {
    display: block;
    padding: 0 3px;

    @include media('screen', '>=desktop') {
      width: calc(100% - 97px);
      padding-right: 15px;
      padding-left: 0;
    }

    &.my-compton {
      text-transform: uppercase;
      padding-top: 12px;
      position: relative;
      font-size: 17px;

      @include media('screen', '>=desktop') {
        font-size: 30px;
        padding-top: 26px;
      }

      @include media('screen', '>=laptop') {
        font-size: 38px;
      }

      .my {
        font-size: 0;
        line-height: 0;
        width: 25px;
        height: 21px;
        position: absolute;
        background: url(../images/text-my.png);
        background-size: 100% 100%;
        top: 2px;
        left: 50%;
        transform: translateX(-29px);

        @include media('screen', '>=desktop') {
          width: 54px;
          height: 37px;
          top: 5px;
          transform: translateX(-67px);
        }
      }
    }
  }

  .img-holder {
    width: 112px;
    height: 112px;
    background: #e3e3e3;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include transition(background);

    @include media('screen', '>=desktop') {
      width: 97px;
      background: transparent;
      border-radius: 0;
      margin-bottom: 0;
    }
  }
}

.why-compton {
  position: relative;

  .container {
    padding: 0;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .tilted-text {
    position: absolute;
    left: -1px;
    top: 64px;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $gray-200;
    letter-spacing: 0.03em;
    z-index: 3;

    @include media('screen', '>=desktop') {
      left: 25px;
      top: 34px;
    }

    @include media('screen', '>=laptop') {
      left: 15px;
      top: 174px;
    }

    @include media('screen', '>=1414px') {
      left: calc(50vw - 690px);
    }

    span {
      position: absolute;
      white-space: nowrap;
    }

    .chevrons {
      margin-left: 11px;
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      transform: rotate(-45deg);
    }
  }

  .img-holder {
    height: 422px;
    position: relative;
    overflow: hidden;

    @include media('screen', '>=439px') {
      height: 524px;
    }

    @include media('screen', '>=desktop') {
      border-radius: 8px 8px 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
    }

    .mountains {
      position: absolute;
      left: 50%;
      bottom: 0;
      background: url(../images/why-decor-mobile.png);
      width: 438px;
      margin-left: -219px;
      height: 173px;
      z-index: 2;

      @include media('screen', '>=439px') {
        background: url(../images/why-compton.png);
        width: 1360px;
        height: 232px;
        margin-left: -680px;
      }
    }

    .sun {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 236px;
      background: url(../images/circle-compton.svg);
      width: 191px;
      height: 190px;
      margin-left: -242px;

      @include media('screen', '>=tablet') {
        display: block;
      }
    }
  }
}

.academic-programs {
  padding-bottom: 108px;

  @include media('screen', '>=desktop') {
    padding-bottom: 0;
  }

  .container {
    padding: 0;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .wrap {
    background: #e2e2e2;
    display: flex;
    flex-wrap: wrap;

    @include media('screen', '>=desktop') {
      padding: 0 34px;
    }
  }

  .text-holder {
    padding: 48px 32px 13px;
    line-height: 1.5;

    @include media('screen', '>=desktop') {
      margin-top: -122px;
      padding: 35px 10px;
      line-height: 1.65;
    }

    @include media('screen', '>=laptop') {
      padding: 55px 16px;
    }
  }

  h2 {
    line-height: 1.3;
    margin-bottom: 24px;

    @include media('screen', '>=desktop') {
      line-height: 1.1;
    }
  }

  p {
    margin-bottom: 51px;
  }

  .btn {
    width: 100%;
    font-size: 23px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 16px 60px 16px 28px;
    letter-spacing: 0.04em;
    margin-left: -6px;

    @include media('screen', '>=desktop') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 2px;
      text-align: center;
      padding: 8px 46px 8px 17px;
    }

    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }

  .btn-clone {
    width: 100%;
    padding: 0 37px;
  }
}

.program-slider {
  width: 100%;
  margin-bottom: 36px;
  padding: 0 43px;

  @include media('screen', '>=desktop') {
    display: flex;
    flex-wrap: wrap;
    padding: 122px 0 87px;
    margin-bottom: 40px;
  }

  .slick-dots {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      margin: 0 7px;
    }

    .slick-active {
      button {
        background: #c00a3e;
      }
    }
  }

  .slick-arrow {
    top: 120px;
    color: #9db2bb;
    cursor: pointer;
    @include transition(color);

    &:hover {
      color: $brown;
    }

    &.slick-prev {
      left: 2px;
    }

    &.slick-next {
      right: 2px;
    }
  }

  .slick-slide {
    @include media('screen', '>=600px') {
      padding: 0 5px;
    }
  }

  > div {
    @include media('screen', '>=desktop') {
      width: 33.33%;
      padding: 0 12px 12px;
    }

    @include media('screen', '>=laptop') {
      padding: 0 23px 12px;
    }
  }

  a:not(.btn) {
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: none;
      color: $red;

      .tilted-text {
        left: 13px;
        z-index: 2;
      }

      .img-block {
        img {
          transform: scale(1.1);
        }
      }

      .title {
        text-decoration: underline;
      }
    }
  }

  .img-block {
    height: 275px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-bottom: 19px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @include media('screen', '>=desktop') {
      height: 306px;
      margin-bottom: 15px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      @include transition(transform);
    }
  }

  .tilted-text {
    position: absolute;
    left: -70px;
    top: 18px;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $white;
    letter-spacing: 0.03em;
    @include transition(left);

    @include media('screen', '>=desktop') {
      left: -70px;
      top: 22px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -999px;
      right: -999px;
      z-index: -1;
      background: $red;
    }

    span {
      position: absolute;
      // white-space: nowrap;
      display: flex;
      align-items: center;
      height: 100%;

      em {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
      }
    }

    .chevrons {
      margin-left: -10px;

      @include media('screen', '>=desktop') {
        margin-left: -1px;
      }
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      border-color: transparent $white $white transparent;
      transform: rotate(-45deg);
    }
  }

  .title {
    display: block;
    text-align: center;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 900;
    color: $brown;
  }
}

.student-life {
  position: relative;
  margin-bottom: 80px;

  @include media('screen', '>=desktop') {
    margin-bottom: 100px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -374px;
    left: 0;
    right: 0;
    bottom: -100px;
    background: #e2e2e2;
    z-index: -1;

    @include media('screen', '>=desktop') {
      background: $brown;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -58px;
    height: 3px;
    background: $white;
    left: 0;
    right: 0;
    z-index: -1;

    @include media('screen', '>=desktop') {
      content: none;
    }
  }

  .decor-below {
    display: none;
    position: absolute;
    bottom: 56px;
    right: 0;
    background: url(../images/student-life-decor01.png);
    width: 933px;
    height: 474px;
    z-index: 1;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }
  .decor-above {
    display: none;
    position: absolute;
    bottom: -65px;
    right: 0;
    background: url(../images/student-life02.png);
    width: 896px;
    height: 253px;
    z-index: 3;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .wrap {
    background: #e2e2e2;

    @include media('screen', '>=desktop') {
      padding: 0 64px 60px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 0 0 10px 10px;
      position: relative;
    }

    &:after {
      position: absolute;
      height: 3px;
      left: 0;
      right: 0;
      background: $white;
      bottom: 22px;

      @include media('screen', '>=desktop') {
        content: '';
      }
    }
  }

  .text-holder {
    line-height: 1.45;

    @include media('screen', '>=desktop') {
      width: 50%;
      padding-right: 50px;
      padding-bottom: 150px;
      line-height: 1.63;
    }
  }

  .move-image-here {
    height: 385px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 47px;

    @include media('screen', '>=desktop') {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  h2 {
    margin-bottom: 30px;

    @include media('screen', '>=desktop') {
      margin-bottom: 20px;
    }
  }

  p {
    margin-bottom: 48px;

    @include media('screen', '>=desktop') {
      margin-bottom: 59px;
    }
  }

  .btn {
    width: 100%;
    font-size: 23px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 19px 60px 18px 28px;
    letter-spacing: 0.04em;
    margin-left: 2px;

    @include media('screen', '>=desktop') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 2px;
      text-align: center;
      padding: 8px 48px 8px 17px;
    }

    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }

  .img-holder {
    border-radius: 20px;
    overflow: hidden;

    @include media('screen', '>=desktop') {
      width: 50%;
      height: 616px;
      position: relative;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

.whats-going-on {
  background: $brown;
  color: $white;
  position: relative;
  padding: 55px 0 70px;

  @include media('screen', '>=desktop') {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 103px;
    padding-bottom: 250px;
  }

  @include media('screen', '>=widescreen') {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 443px;
  }

  .tilted-text {
    position: absolute;
    right: 8px;
    top: 58px;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $gray-200;
    letter-spacing: 0.03em;

    @include media('screen', '>=desktop') {
      right: 15px;
      top: 81px;
    }

    @include media('screen', '>=laptop') {
      right: 47px;
    }

    span {
      position: absolute;
      white-space: nowrap;
    }

    .chevrons {
      margin-left: 11px;
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      transform: rotate(-45deg);
    }
  }

  .container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      max-width: 1260px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 15px;
    }

    &:after {
      content: none;
    }
  }

  .left-block {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('screen', '>=desktop') {
      width: 43.5%;
    }
  }

  .move-embed-here {
    height: 463px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('screen', '>=desktop') {
      display: none;
    }
  }

  .right-block {
    padding-right: 35px;
    line-height: 1.45;

    @include media('screen', '>=desktop') {
      width: 43.5%;
      line-height: 1.65;
    }

    @include media('screen', '>=widescreen') {
      padding-right: 0;
    }
  }

  h2 {
    color: $white;
    letter-spacing: -0.04em;
    margin-bottom: 49px;
    line-height: 1.55;

    @include media('screen', '>=desktop') {
      line-height: 1.2;
      margin-bottom: 39px;
    }
  }

  p {
    margin-bottom: 52px;
  }

  .btn {
    
    .mobile-only {
      @include media('screen', '>=desktop') {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }
  }
}

.more-success-stories {
  position: relative;
  padding-top: 70px;
  padding-bottom: 30px;

  @include media('screen', '>=desktop') {
    padding: 0;
  }

  &.mobile-hidden {
    display: none;

    @include media('screen', '>=desktop') {
      display: block;
    }
  }

  &:hover {

    .text-holder {

      .tilted-text {

        @include media('screen', '>=desktop') {
          left: 13px;
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 434px;
    left: 0;
    right: 0;
    background: $brown;
    z-index: -1;

    @include media('screen', '>=desktop') {
      height: 359px;
    }

    @include media('screen', '>=widescreen') {
      height: 565px;
    }
  }

  .quote-wrap {
    padding: 20px 0 32px;

    @include media('screen', '>=desktop') {
      padding: 30px 15px;
    }

    @include media('screen', '>=laptop') {
      padding: 30px 35px;
    }
  }

  &.reverse {

    &:hover {

      .text-holder {

        .tilted-text {

          @include media('screen', '>=desktop') {
            @include animate(right);
            right: 13px;
          }
        }
      }
    }

    .text-holder {
      @include media('screen', '>=desktop') {
        border-radius: 0 10px 10px 0;
        order: 2;
      }

      &.has-indent {
        @include media('screen', '>=desktop') {
          padding-left: 0;
          padding-right: 100px;
        }
      }

      .tilted-text {
        @include media('screen', '>=desktop') {
          @include animate(right);
          left: auto;
          right: -100%;
        }
      }
    }

    .img-holder {
      .map {
        @include media('screen', '>=desktop') {
          border-radius: 10px 0 0 10px;
        }
      }
    }
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .wrap {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 12px;
    background: transparent;

    @include media('screen', '>=desktop') {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      background: $white;
    }
  }

  .text-holder {
    position: relative;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 30px 0;

    @include media('screen', '>=tablet') {
      padding-right: 34px;
    }

    @include media('screen', '>=desktop') {
      width: 50%;
      min-height: 648px;
    }

    &.has-indent {
      // padding-left: 80px;

      @include media('screen', '>=desktop') {
        padding-left: 100px;
      }
    }

    .tilted-text {
      @include media('screen', '>=desktop') {
        @include animate(left);
        position: absolute;
        left: -100%;
        // left: 13px;
        top: 30px;
        transform: rotate(90deg);
        width: 50px;
        height: 50px;
        text-transform: uppercase;
        font-size: 30px;
        font-family: $font-family-sans-serif-secondary;
        font-weight: 400;
        color: $white;
        letter-spacing: 0.023em;
        line-height: 1.2;

        &:before {
          content: '';
          position: absolute;
          top: -20px;
          bottom: -20px;
          left: -999px;
          right: -999px;
          z-index: -1;
          background: $red;
        }

        span {
          position: absolute;
          white-space: nowrap;
        }

        .chevrons {
          margin-left: 16px;
        }

        .chevrons-item {
          margin-left: 1px;
        }

        .chevrons-item:before {
          border-color: transparent $white $white transparent;
          transform: rotate(-45deg);
        }
      }

      > span {
        display: none;

        @include media('screen', '>=desktop') {
          display: block;

          a {
            text-decoration: none;
            color: $white;
            @include transition(opacity);

            &:hover {
              text-decoration: none;
              opacity: 0.6;
            }
          }
        }
      }

      .btn {
        display: block;

        @include media('screen', '>=desktop') {
          display: none;
        }
      }
    }
  }

  .img-holder {
    width: 100%;
    position: relative;
    height: 364px;

    @include media('screen', '>=desktop') {
      width: 50%;
      height: auto;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .map {
      width: 100%;
      height: 100%;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      border-radius: 30px 30px 0 0;
      overflow: hidden;

      @include media('screen', '>=desktop') {
        border-radius: 0 10px 10px 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .student {
      width: 275px;
      max-width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -100px;
      // margin-left: 16px;
      margin-left: -122px;

      &[data-parallax] {
        will-change: transform;
      }

      @include media('screen', '>=desktop') {
        width: 350px;
        margin-left: -159px;
      }

      @include media('screen', '>=laptop') {
        width: 501px;
        margin-left: -235px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../images/circle-more-stories.svg);
        background-size: 100% 100%;
        width: 230px;
        height: 230px;
        z-index: -1;
        margin-left: 0;

        @include media('screen', '>=desktop') {
          width: 300px;
          height: 300px;
          top: -106px;
          margin-left: -10px;
        }

        @include media('screen', '>=laptop') {
          width: 476px;
          height: 476px;
          margin-left: 0;
        }
      }

      img {
        display: block;
      }
    }
  }
}

.quote-wrap {

  h3, .h3 {
    margin: 0;
    color: $primary;
  }

  .sub-heading {
    display: block;
    color: $secondary;
    font-weight: $font-weight-black;
    margin: 0 0 34px;
  }
  blockquote {
    padding: 41px 0 0;
    line-height: 1.48;

    @include media('screen', '>=desktop') {
      font-size: 18px;
      padding-top: 60px;
      padding-bottom: 34px;
      margin: 0;
    }

    @include media('screen', '>=laptop') {
      font-size: 21px;
    }

    q {
      quotes: none;
      display: block;
      margin-bottom: 32px;

      @include media('screen', '>=desktop') {
        margin-bottom: 40px;
      }
    }

    cite {
      margin-left: -8px;
      letter-spacing: 0.08em;
      margin: 0 0 34px;
    }

    &:after {
      bottom: -34px;

      @include media('screen', '>=desktop') {
        bottom: 0;
      }

      @include media('screen', '>=laptop') {
        right: 46px;
        bottom: 0;
      }
    }
  }
}

.esl-students {

  + .info-block {
    // margin-top: -139px;

    @include media('screen', '>=desktop') {
      margin-top: -122px;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1460px;
  }

  .img-holder {
    width: 100%;
    min-height: 340px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include media('screen', '>=desktop') {
      width: 50%;
      min-height: 651px;
    }

    .img-wrap {
      width: 100%;

      @include media('screen', '>=desktop') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        width: 50vw;
        border-radius: 0 0 20px 0;
      
      }

      img {
        width: 100%;
        height: 100%;
        max-width: none;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .text-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 40px;

    @include media('screen', '>=desktop') {
      width: 50%;
    }

    .wrap {

      @include media('screen', '>=desktop') {
        padding-left: 87px;
      }
    }
  }

  h2 {
    letter-spacing: -0.03em;
    margin-bottom: 17px;
  }

  .text-wrap {
    padding-right: 90px;
  }

  p {
    margin-bottom: 53px;
  }

  .btn {
    padding: 14px 70px 14px 22px;
    letter-spacing: 0.09em;
    border-radius: 8px;
  }
}

.has-brown-bg-mobile {
  background: $brown;

  @include media('screen', '>=desktop') {
    background: transparent;
  }
}
