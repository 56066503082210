.vertical-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    font-weight: 400;
    font-family: $font-family-sans-serif-secondary;
    color: $gray-200;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
}

.chevrons {
    display: inline-flex;
    padding-top: 8px;
    padding-bottom: 8px;

    &-item {
        animation: chevron-anim 1s linear infinite alternate;
        margin-bottom: 2px;

        &:before {
            display: inline-block;
            width: 22px;
            height: 22px;
            border: 3px solid;
            border-color: transparent currentColor currentColor transparent;
            transform: rotate(45deg);
            content: '';
            line-height: 1;
        }

        &:nth-child(1) {
            opacity: 0;
        }
    
        &:nth-child(2) {
            opacity: .3;
            animation-delay: .33s
        }
    
        &:nth-child(3) {
            opacity: .6;
            animation-delay: .66s
        }
    
        &:nth-child(4) {
            opacity: 1;
            animation-delay: 1s
        }
    }
}

@keyframes chevron-anim {
    0%   { opacity: .15; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}