.contact-boxes-section {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media('screen', '>=widescreen') {
        padding-top: 132px;
        padding-bottom: 132px;
    }

    & + .info-block {
        margin-top: -100px;

        @include media('screen', '>=widescreen') {
            margin-top: -314px;
        }
    }
}

.contact-boxes {
    @include media('screen', '>=tablet') {
        display: flex;
        justify-content: space-around;
    }

    > * {
        @include media('screen', '>=tablet') {
            flex: 1 1 50%;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 536px;
        }
    }
}

.contact-box {
    margin-bottom: 50px;
    color: $secondary;

    &-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 43px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-title {
        margin-bottom: 25px;
    }

    &-text {
        margin-bottom: 40px;
        color: $secondary;
    }
}