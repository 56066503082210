// -----------------------------------------------------------------------------
// This file contains typography styles.
// -----------------------------------------------------------------------------

/*
  Set up a decent box model on the root element
*/

html {
  font-size: $rem-base;
}

/*
  Basic typography style for copy text
*/
body {
  color: $body-color;
  font-family: $font-family-base;
  font-size: map-get($font-sizes, 'base');
  line-height: $line-height-base;
  font-weight: 500;
}

/*
  Basic styles for links
*/
a {
  @include transition(color);
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  .bg-primary & {
    color: $white;
  }
}

ul,
ol,
dl,
p {
  margin: 0 0 $paragraph-margin-bottom;
}

/*
  Basic styles for typography, generate classes for sizes provided into sass-map $font-sizes
  Using fluid type behavior
*/

// @each $name, $value in $font-sizes {
//   .text-size-#{$name} {
//     $max-value: map-get($font-sizes-max, $name);

//     @include fluid-type($value, $max-value);
//   }
// }

/*
  Basic styles for headings, generate classes for sizes provided into sass-map $font-sizes
  Using fluid type behavior
*/

// @each $name, $value in $headings-sizes {
//   #{$name},
//   .#{$name} {
//     $max-value: map-get($headings-sizes-max, $name);

//     @include fluid-type($value, $max-value);
//     margin: 0 0 $headings-margin-bottom;
//     line-height: $headings-line-height;
//   }
// }

// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  margin: 0 0 $headings-margin-bottom;
  line-height: 1.07;
  color: $brown;

  .bg-secondary &,
  .bg-primary & {
    color: $white;
  }
}

h1, .h1 {
  font-size: 55px;
  font-family: $font-family-serif;

  @include media('screen', '>=laptop') {
    font-size: 75px;
  }
}

h2, .h2 {
  font-size: 50px;
  font-family: $font-family-serif;

  @include media('screen', '>=laptop') {
    font-size: 70px;
  }
}

h3, .h3 {
  font-size: 30px;
  font-weight: 400;
  font-family: $font-family-sans-serif-secondary;
  color: $gray-300;
}

h4, .h4 {
  font-size: 22px;
  font-family: $font-family-sans-serif;
  font-weight: 900;
}

h5, .h5 {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  font-weight: 400;
}
