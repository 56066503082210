
.chat-section {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: $white;

    @include media('screen', '>=desktop') {
      height: 150px;
    }

    @include media('screen', '>=laptop') {
      height: 250px;
    }

    @include media('screen', '>=widescreen') {
      height: 371px;
    }
  }

  &-box {
    padding-bottom: 50%;
    color: $secondary;

    @include media('screen', '>=desktop') {
      display: flex;
      justify-content: space-between;
      padding-bottom: 250px;
    }

    @include media('screen', '>=widescreen') {
      padding-bottom: 380px;
    }

    &-column {
      padding: 0 0 40px;

      @include media('screen', '>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px 30px;
      }

      @include media('screen', '>=laptop') {
        padding: 0 45px 30px;
      }

      h2, .h2 {
        color: $brown;
      }
    }
 
    &-smaller {
      position: relative;
      background: $brand-color;
      color: $white;
      border-radius: 4px;
      margin: -40% 25px 0;

      @include media('screen', '>=desktop') {
        max-width: 80%;
        display: flex;
        justify-content: space-between;
        margin: -230px auto 60px;
      }

      @include media('screen', '>=widescreen') {
        max-width: 1140px;
      }

      h2, .h2,
      .link {
        color: currentColor;
      }
    }
  }

  .counselor.counselor {
    color: $white;
    padding: 0;
  }
}


.counselor-decors {

  @include media('screen', '>=widescreen') {
    bottom: -80px;
  }
}

.counselor-decor-3 {

  @include media('screen', '>=widescreen') {
    bottom: 3%;
  }
}