.department-section {
    padding-top: 48px;
    padding-bottom: 48px;

    &-header {
        @include media('screen', '>=desktop') {
            width: 70%;
            margin-bottom: 55px;
        }
    }

    &-title {
        margin-bottom: 18px;
    }

    &-text {
        @include media('screen', '>=desktop') {
            width: 70%;
        }
    }
}

.department-section + .info-block {
    margin-top: -50px;

    @include media('screen', '>=widescreen') {
        margin-top: -103px;
    }
}

.department-accordion {
    @extend %listreset;

    @include media('screen', '>=tablet') {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
    }

    @include media('screen', '>=widescreen') {
        margin-left: -15px;
        margin-right: -15px;
    }

    &-holder {
        @include media('screen', '>=widescreen') {
            padding-right: 55px;
        }
    }

    &-open {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 16px 7px 22px;
        font-size: 30px;
        font-family: $font-family-sans-serif-secondary;
        color: $secondary;
        background-color: $gray-100;
        border-radius: 5px;
        transition: background-color .3s ease, color .3s ease;

        &:hover {
            background-color: $primary;
            color: $white;
            text-decoration: none;
        }
    }

    &-item {
        margin-bottom: 20px;

        @include media('screen', '>=tablet') {
            flex: 1 1 calc(50% - 20px);
            max-width: calc(50% - 20px);
            margin-left: 10px;
            margin-right: 10px;
        }

        @include media('screen', '>=widescreen') {
            flex: 1 1 calc(50% - 30px);
            max-width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 52px;
        }

        &.active {

            > a {
                background-color: $primary;
                color: $white;
                text-decoration: none;
            }
        }
    }

    &-slide {
        padding: 10px 20px;

        p {
            margin-bottom: 0;
        }
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        font-size: 16px;
        color: $primary;
        background-color: $white;
        border-radius: 50%;
        transition: transform .3s ease;

        .active & {
            transform: rotate(90deg);
        }
    }
}