.hero-block {
    position: relative;
    padding-top: 38px;
    padding-bottom: 30px;

    @include media('screen', '>=desktop') {
        padding-top: 88px;
    }

    &-inner {
        @include media('screen', '>=desktop') {
            display: flex;
            margin-left: -37px;
            margin-right: -37px;
        }
    }

    &-col-left {
        padding-left: 48px;
        // padding-right: 48px;
        // text-align: center;

        @include media('screen', '>=tablet') {
            padding-right: 48px;
        }
    }

    &-col-right {
        padding-left: 48px;

        @include media('screen', '>=tablet') {
            padding-left: 0;
        }
    }

    &-col-left,
    &-col-right {
        @include media('screen', '>=desktop') {
            flex: 1 1 50%;
            max-width: 50%;
            padding-left: 37px;
            padding-right: 37px;
        }
    }

    &-media {
        position: relative;
        height: 408px;
        margin-right: -15px;
        border-radius: 20px 0 0 20px;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

        @include media('screen', '>=desktop') {
            margin-top: 18px;
        }

        @include media('screen', '>=widescreen') {
            margin-right: calc((100vw - 1380px) / 2 * -1);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-mobile-hide {
        @include media('screen', '<desktop') {
            display: none;
        }
    }

    &-title {
        margin-bottom: 34px;

        @include media('screen', '>=widescreen') {
            margin-bottom: 25px;
        }
    }

    &-vertical-title {
        position: absolute;
        top: 45px;
        left: 0;

        @include media('screen', '>=tablet') {
            left: 18px;
        }

        @include media('screen', '>=widescreen') {
            top: 158px;
            left: 33px;
        }
    }
}