// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// 2. Vendors: include media mixins and mormalize imported from node_modules
@import '~include-media/dist/include-media';
@import '~normalize.css';
@import 'vendors/icomoon/styles';
@import 'vendors/mejs/mejs';

// 3. Base styles and elements
@import 'base/base';
@import 'base/helpers';
@import 'base/fonts';
@import 'base/typography';
@import 'base/animation';
@import 'base/forms';

// 4. Layout-related sections
@import 'layout/header';
@import 'layout/common';
@import 'layout/footer';

// 5. Components
@import 'components/button';
@import 'components/tabs';
@import 'components/slick';
@import 'components/container';
@import 'components/widget';
@import 'components/table';
@import 'components/hero-block';
@import 'components/breadcrumb';
@import 'components/scroll-to-top';
@import 'components/news';
@import 'components/pagination';
@import 'components/social-bar';
@import 'components/contact-box';
@import 'components/info-block';
@import 'components/program-row';
@import 'components/department';
@import 'components/vertical-title';
@import 'components/custom-video';
@import 'components/contact';
@import 'components/default-elements';
@import 'components/side-nav';
@import 'components/content-sidebar';

// 6. Page-specific styles
@import 'pages/home';
@import 'pages/welcome';
@import 'pages/inners';
@import 'pages/about';
@import 'pages/guided';
@import 'pages/components';

.no-scroll {
  overflow: hidden;
}