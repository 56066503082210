// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------

.footer {
    position: relative;
    background-color: #e3e3e3;
    text-align: center;
    overflow: hidden;

    @include media('screen', '>=tablet') {
        text-align: left;
    }

    &-decor {
        display: none;

        @include media('screen', '>=tablet') {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            bottom: 0;
            width: 1200px;
            pointer-events: none;
        }

        @include media('screen', '>=desktop') {
            width: 900px;
        }

        @include media('screen', '>=widescreen') {
            width: 73%;
        }

        img {
            width: 100%;
            height: 100%;
            vertical-align: bottom;
        }
    }

    &-inner {
        @include media('screen', '>=desktop') {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }

    &-area-1 {
        padding-top: 20px;
        padding-bottom: 48px;

        @include media('screen', '>=tablet') {
            display: flex;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include media('screen', '>=desktop') {
            flex: 1 1 70%;
            max-width: 70%;
            padding-top: 0;
            padding-bottom: 0;
        }

        @include media('screen', '>=widescreen') {
            flex: 1 1 54%;
            max-width: 54%;
        }

        &-col {
            @include media('screen', '>=tablet') {
                flex: 1 1 33.33333%;
                max-width: 33.33333%;
                padding-right: 30px;
            }

            &-1 {
                margin-bottom: 37px;

                @include media('screen', '>=tablet') {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    align-items: flex-start;
                    margin-bottom: 0;
                }
            }

            &-3 {
                @include media('screen', '>=tablet') {
                    padding-right: 0;
                }
            }
        }
    }

    &-area-2 {
        position: relative;
        z-index: 1;
        padding-top: 55px;
        padding-bottom: 55px;

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            z-index: -1;
            transform: translateX(-50%);
            width: 100vw;
            background-color: $secondary;
            content: '';

            @include media('screen', '>=tablet') {
                display: none;
            }
        }

        @include media('screen', '>=tablet') {
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-end;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include media('screen', '>=desktop') {
            flex-direction: column-reverse;
            flex: 1 1 30%;
            max-width: 30%;
            padding-top: 0;
            padding-bottom: 0;
        }

        @include media('screen', '>=widescreen') {
            flex: 1 1 46%;
            max-width: 501px;
            flex-direction: row-reverse;
        }

        &-col {
            &-1 {
                @include media('screen', '>=tablet') {
                    flex: 1 1 40%;
                    max-width: 40%;
                    text-align: right;
                    padding-right: 48px;
                }

                @include media('screen', '>=desktop') {
                    flex: 1 1 100%;
                    max-width: 100%;
                    padding-right: 0;
                }

                @include media('screen', '>=widescreen') {
                    flex: 1 1 40%;
                    max-width: 40%;
                }
            }

            &-2 {
                @include media('screen', '>=tablet') {
                    flex: 1 1 60%;
                    max-width: 60%;
                }

                @include media('screen', '>=desktop') {
                    flex: 1 1 100%;
                    max-width: 100%;
                }

                @include media('screen', '>=widescreen') {
                    flex: 1 1 60%;
                    max-width: 60%;
                    padding-right: 30px;
                }
            }
        }
    }

    &-add-links {
        @extend %listreset;
        margin-bottom: 0;

        li {
            margin-bottom: 15px;

            @include media('screen', '>=desktop') {
                margin-bottom: 9px;
            }
        }

        a {
            color: $white;

            @include media('screen', '>=desktop') {
                font-size: 16px;
            }
        }
    }

    &-copyright {
        margin-bottom: 46px;
        color: $white;

        @include media('screen', '>=tablet') {
            margin-bottom: 0;
        }

        @include media('screen', '>=desktop') {
            font-size: 16px;
        }
    }

    &-main-links {
        @extend %listreset;
        margin-bottom: 37px;

        @include media('screen', '>=tablet') {
            margin-bottom: 0;
        }

        li {
            margin-bottom: 20px;
        }

        a {
            font-size: 30px;
            font-weight: 400;
            font-family: $font-family-sans-serif-secondary;
            color: $body-color;
            text-transform: uppercase;

            &:hover {
                color: $primary;
                text-decoration: none;
            }

            @include media('screen', '>=tablet') {
                text-transform: none;
            }
        }
    }

    &-address {
        margin-bottom: 35px;
        font-style: normal;
        color: $secondary;

        &-title {
            display: block;
            font-weight: 800;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-logo {
        width: 140px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        
        @include media('screen', '>=tablet') {
            width: 114px;
            margin-left: 8px;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    &-tel {
        font-size: 22px;
        font-weight: 800;
    }

    &-social-bar {
        margin-bottom: 42px;

        @include media('screen', '>=desktop') {
            margin-bottom: 30px;
        }

        @include media('screen', '>=widescreen') {
            margin-bottom: 68px;
        }
    }

    &-link {
        @include media('screen', '>=tablet') {
            margin-bottom: 42px;
            text-transform: none;
        }
    }

    &-google-translate {
        @include media('screen', '>=tablet') {
            width: 227px;
            margin-bottom: 16px;
        }
    }

    &-opener {
        display: block;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        color: #565856;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    
        svg {
            vertical-align: middle;
            transition: transform .3s ease;

            @include media('screen', '<phone-lg') {
                width: 100px;
                height: 70px;
            }

            .active & {
                transform: rotate(180deg);
            }
        }
    }
}