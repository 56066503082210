/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

.listreset {
  padding: 0;
  margin: 0;
  list-style: none;
}

.text-center {
  text-align: center;
}

%listreset {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

%listreset-custom {
  margin-bottom: 0;

  li {
      &:before {
          display: none !important;
      }
  }
}

.bg-primary {
  background-color: $primary;
  color: $white;
}

.bg-secondary {
  background-color: $secondary;
  color: $white;
}

.mb-0 {
  margin-bottom: 0;
}