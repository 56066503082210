
.loader {
	width: 100%;
	height: 100%;
	background: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	div {
		height: 50px;
		width: 20px;
		border-radius: 15px;
		background-color: $secondary;
		margin: 5px;
		animation: stretch 2s infinite;
	}

	.bar2 {
		animation-delay: 0.25s;
	}

	.bar3 {
		animation-delay: 0.50s;
	}

	.is-loaded & {
		display: none;
	}
}

.btn-holder {
	text-align: center;
	padding: 0 30px;
}

.bg-secondary {
	background: $secondary;
	color: $white;
}


.services {
	margin: 30px 0;

	@include media('screen', '>=desktop') {
		margin: 60px 0;
	}

	@include media('screen', '>=widescreen') {
		margin-bottom: 108px;
	}
}

.box {
	background: $gray-100;
	position: relative;
	padding: 92px 30px;

	@include media('screen', '>=phone') {
		border-radius: 10px;
	}

	@include media('screen', '>=desktop') {
		margin: 0 30px;
	}

	@include media('screen', '>=laptop') {
		max-width: 1380px;
		margin: 0 60px;
	}

	@include media('screen', '>=widescreen') {
		margin: 0 auto;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		background: $white;
	}

	&:before {
		top: 20px;
	}

	&:after {
		bottom: 20px;
	}
}

.mission {

	&-box {
		padding-bottom: 50%;
		color: $secondary;

		@include media('screen', '>=desktop') {
			display: flex;
			justify-content: space-between;
			padding-bottom: 250px;
		}

		@include media('screen', '>=widescreen') {
			padding-bottom: 380px;
		}

		&-column {
			padding: 0 0 40px;

			@include media('screen', '>=desktop') {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 15px 30px;
			}

			@include media('screen', '>=laptop') {
				padding: 0 45px 30px;
			}
		}

		&-smaller {
			position: relative;
			background: $brand-color;
			color: $white;
			border-radius: 4px;
			margin: -50% 25px 0;

      @include media('screen', '>=phone-lg') {
        margin: -43% 25px 0;
      }

      @include media('screen', '>=tablet') {
        margin: -40% 25px 0;
      }

			@include media('screen', '>=desktop') {
				max-width: 80%;
				display: flex;
				justify-content: space-between;
				border-radius: 10px;
				margin: -200px auto 60px;
			}

			@include media('screen', '>=laptop') {
				margin-bottom: 115px;
			}

			@include media('screen', '>=widescreen') {
				max-width: 1140px;
			}

			.mission-box-column {
				padding: 35px 24px;

				@include media('screen', '>=laptop') {
					padding: 66px 30px 66px 66px;
					flex: 0 0 50%;
					max-width: 50%;
				}
			}

			h2, .h2,
			.link {
				color: currentColor;
			}
		}

		&-illustration {
			position: relative;
			//background: url(../images/pattern01.jpg) no-repeat;
			//background-size: cover;
			border-radius: 4px 4px 0 0;

			@include media('screen', '>=desktop') {
				order: 2;
				flex: 0 0 50%;
				max-width: 50%;
				border-radius: 0 10px 10px 0;
        overflow: hidden;
			}

      .mission-pattern {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        max-height: 400px;

        @include media('screen', '>desktop') {
          max-height: 100%;
        }

        img {
          margin: 0;
          width: 100%;
        }
      }

      .mission-box-hero {
        position: absolute;
        left: 15px;
        bottom: 0;
      }

			img {
				display: block;
			}
		}
	}
}

.departments {
	position: relative;
	overflow: hidden;

	&-heading-block {
		text-align: center;
		max-width: 95%;
		margin: 0 auto 30px;
		padding: 0 30px 0 0;

		@include media('screen', '>=laptop') {
			max-width: none;
			margin-bottom: 60px;
		}

		h2, .h2 {
			color: currentColor;
		}

		p {

			@include media('screen', '>=desktop') {
				padding: 0 2%;
			}
		}
	}

	&-vertical-title {
		position: absolute;
		top: 80px;
		right: 10px;

		@include media('screen', '>=desktop') {
			right: 60px;
		}
	}

	&-list {
		@extend .listreset;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -5px 30px;
		font-size: 1.8rem;

		@include media('>=phone') {
			margin: 0 -15px 30px;
		}

		@include media('screen', '>=desktop') {
			margin: 0 -35px 90px;
			font-size: 2.1rem;
		}

		@include media('screen', '>=laptop') {
			justify-content: space-between;
		}

		li {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 5px 25px;
			text-align: center;

			@include media('screen', '>=widescreen') {
				padding: 0 15px 25px;
			}

			@include media('screen', '>=desktop') {
				flex: 0 0 33.3%;
				max-width: 33.3%;
				padding: 0 35px;
				text-align: left;
			}

			@include media('screen', '>=laptop') {
				flex: 0 0 auto;
				max-width: none;
			}
		}

		a {
			color: currentColor;
		}

		.icon {
			display: block;
			margin: 0 auto 22px;
		}

		.title {
			display: block;
			margin: 0 0 6px;
			line-height: 1.1;
			font-weight: $font-weight-black;
			text-transform: capitalize;

			@include media('screen', '>=desktop') {
				font-size: 2.2rem;
				margin: 0 0 11px;
			}
		}

		.link {
			display: block;
			margin: 0 0 6px;

			@include media('screen', '>=desktop') {
				margin: 0 0 11px;
			}
		}
	}

	.container {

		@include media('screen', '>=desktop') {
			padding: 0 30px;
		}

		@include media('screen', '>=laptop') {
			padding: 0 60px;
		}
	}

	.bg-secondary.bg-secondary {
		padding: 90px 0 150px;
		position: relative;

		@include media('screen', '>=desktop') {
			margin-top: 30px;
		}

		@include media('screen', '>=laptop') {
			padding: 120px 0;
			margin-top: -100px;
		}

		@include media('screen', '>=widescreen') {
			padding: 290px 0 310px;
		}
	}

	&-decors {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;

		@include media('screen', '>=desktop') {
			width: auto;
		}
	}

	&-decor {
		position: absolute;
		right: 0;

		&01 {
			bottom: 0;
		}

		&02 {
			bottom: 0;

			@include media('screen', '>=desktop') {
				bottom: 60px;
			}
		}
	}
}

.img-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 34px;
	padding: 0 15px;
	position: relative;
	z-index: 2;

	@include media('screen', '>=desktop') {
		margin: 0 -15px;
	}

	.column {

		@include media('screen', '>=desktop') {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 0 15px;
			flex: 0 0 50%;
			max-width: 50%;
		}

		> div {
			width: 100%;
			overflow: hidden;
			border-radius: 10px;
			-webkit-mask-image: -webkit-radial-gradient(white, black);

			a {
				display: block;
				height: 100%;
				border-radius: 10px;
				overflow: hidden;
				-webkit-mask-image: -webkit-radial-gradient(white, black);

				&:hover {

					img {
						transform: scale(1.2);
					}
				}
			}
		}
	}

	.v-half {
		margin: 17px 0;

		@include media('screen', '>=desktop') {
			height: 50%;
			margin: 0 0 17px;
		}
	}

	.v-full {

		@include media('screen', '>=desktop') {
			height: 100%;
			margin: 0 0 17px;
		}
	}

	img {
		@include animate(transform);
		display: flex;
		border-radius: 10px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.history {
	padding: 0 0 50px;

	@include media('screen', '>=desktop') {
		padding: 0 0 135px;
	}

	&-intro {
		position: relative;
		padding: 50px 15px;
		margin: 0 0 50px;

		@include media('screen', '>=desktop') {
			padding-bottom: 0;
			margin: 0;
		}

		@include media('screen', '>=laptop') {
			// padding-bottom: 80px;
		}

		.container {

			@include media('screen', '>=desktop') {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}

		&-image {
			padding: 0 0 30px;

			@include media('screen', '>=desktop') {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 60px 0 100px;
				margin-top: -50px;
			}

			@include media('screen', '>=laptop') {
				margin-top: -100px;
			}

			@include media('screen', '>=widescreen') {
				margin-top: -150px;
				padding-left: 0;
			}

			img {
				display: block;
				margin: 0 auto;
			}
		}

		&-text {

			@include media('screen', '>=desktop') {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 0 0 60px;
			}

			@include media('screen', '>=laptop') {
				padding-bottom: 50px;
			}
		}
	}

	&-box {
		padding: 50px 20px;

		@include media('screen', '>=laptop') {
			padding-bottom: 80px;
		}

		&:before {
			top: 30px;
		}

		.fw-gallery {
			padding: 0 30px;

			@include media('screen', '>=widescreen') {
				padding: 0 80px;
			}

			&:before {

				@include media('screen', '>=desktop') {
					content: "";
					position: absolute;
					top: -28px;
					left: -20px;
					height: 21px;
					width: 870px;
					background: url(../images/box-decor01.png) no-repeat;
				}
			}

			.slick-dots {
				margin-top: 20px;

				@include media('screen', '>=desktop') {
					margin-top: 25px;
				}
			}
		}

		.slick-arrow {
			top: 50%;

			@include media('screen', '>=desktop') {
				transform: translateY(-50%);
			}

			&.slick-prev {
				left: 0;

				@include media('screen', '>=desktop') {
					left: -10px;
				}
			}

			&.slick-next {
				right: 0;

				@include media('screen', '>=desktop') {
					right: -10px;
				}
			}
		}
	}

	&-slide {

		.slick-initialized & {

			@include media('screen', '>=desktop') {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}
	}

	&-text {
		color: $brand-color-dark;

		@include media('screen', '>=desktop') {
			flex: 0 0 60%;
			max-width: 60%;
			padding: 0 50px 0 0;
		}

		@include media('screen', '>=laptop') {
			padding: 0 85px 0 0;
		}

		h3, .h3 {
			letter-spacing: .3px;
			margin: 0 0 25px;
			text-align: center;
			color: currentColor;

			@include media('screen', '>=desktop') {
				text-align: left;
				margin: 0 0 53px;
			}
		}

		.date {
			font: $font-weight-medium 4.5rem/1 $font-family-serif;
			color: $brand-color;
			display: inline-block;
			vertical-align: middle;
			padding-left: 10px;

			@include media('screen', '>=desktop') {
				font-size: 9rem;
			}

			@include media('screen', '>=widescreen') {
				font-size: 16.5rem;
				padding-left: 32px;
			}
		}

		p {

			@include media('screen', '>=desktop') {
				margin: 0;
			}
		}
	}

	&-img {

		@include media('screen', '>=desktop') {
			flex: 0 0 40%;
			max-width: 40%;
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}

	&-decors {
		display: none;

		@include media('screen', '>=desktop') {
			display: block;
			position: absolute;
			bottom: -40px;
			left: 0;
			width: 40%;
		}

		@include media('screen', '>=laptop') {
			width: 50%;
		}

		@include media('screen', '>=widescreen') {
			width: auto;
		}
	}

	&-decor {
		position: absolute;
		left: 0;

		&01 {
			bottom: 0;

			@include media('screen', '>=desktop') {
				bottom: 40px;
			}
		}

		&02 {
			bottom: 60px;

			@include media('screen', '>=desktop') {
				bottom: 100px;
			}

			@include media('screen', '>=widescreen') {
				bottom: 160px;
			}
		}

		&03 {
			bottom: 100px;

			@include media('screen', '>=desktop') {
				bottom: 200px;
			}

			@include media('screen', '>=widescreen') {
				bottom: 300px;
			}
		}

		&02,
		&03 {
			z-index: -1;
		}
	}
}

.tab-gallery {
	margin: 0 0 40px;

	@include media('screen', '>=desktop') {
		margin: 0 0 60px;
	}

	@include media('screen', '>=widescreen') {
		margin: 0 0 118px;
	}

	> .container {

		> h2, .h2 {

			@include media('screen', '>=widescreen') {
				margin: 0 0 42px;
			}
		}
	}

	.dropdown {
		position: relative;
		z-index: 2;
		margin: 0 15px 20px;

		@include media('screen', '>=laptop') {
			margin: 0 45px 40px;
		}

		&.open {

			.caption {

				[class^="icon"],
					[class*="icon"] {

						&:before {
							transform: rotate(135deg);
						}
					}
				}

			.list {
				display: block;

				@include media('screen', '>=desktop') {
					display: flex;
				}
			}
		}

		.caption {
			display: block;
			cursor: pointer;
			font-size: 2.4rem;
			font-weight: $font-weight-black;
			color: $primary;
			background: #e3e3e3;
			padding: 10px 40px 10px 25px;
			border-radius: 6px;
			position: relative;

			@include media('screen', '>=desktop') {
				display: none;
			}

			&:before {
	        content: '';
	        position: absolute;
	        top: 50%;
	        right: 25px;
	        transform: translateY(-50%) rotate(-135deg);
	        height: 22px;
	        width: 22px;
	        border: solid #9db2bb;
	        border-width: 0 0 3px 3px;
	        @include transition(border-color);

	        @include media('screen', '>=desktop') {
	          height: 13px;
	          width: 13px;
	          right: 17px;
	        }
	      }
		}
	 }

	.nav-select {
		@extend .listreset;

		@include media('screen', '>=desktop') {
			display: flex;
			justify-content: center;
			padding: 0 40px;
			margin-bottom: 37px;
		}

		@include media('screen', '>=laptop') {
			padding: 0;
		}

		> li {

			@include media('screen', '>=desktop') {
				margin: 0 6px 12px;
			}

			&.active {

				a {
					background: $gray-100;
					color: $link-hover-color;
				}
			}

			> a {
				@include transition(background color);
				padding: 11px 24px;
				display: block;
				font-size: 1.8rem;
				line-height: 1.4;
				font-weight: $font-weight-black;
				color: $gray-300;
				text-decoration: none;
				letter-spacing: 0.03em;
				text-align: left;

				@include media('screen', '>=desktop') {
					border-radius: 4px;
					padding: 11px 12px;
					text-align: center;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				@include media('screen', '>=laptop') {
					font-size: 2.2rem;
					padding: 11px 24px;
				}

				&:hover {
					background: $gray-100;
					color: $link-hover-color;
					text-decoration: none;
				}
			}
		}
	}

	.list {
		position: absolute;
		width: 100%;
		display: none;
		background: $white;
		z-index: 20;
		text-align: left;

		@include media('screen', '>=desktop') {
			display: flex;
			position: relative;
			background: transparent;
			width: auto;
			text-align: center;
		}
	 }

	 .slick-slider {

	 	@include media('screen', '>=desktop') {
	 		padding: 0 30px;
	 	}

	 	@include media('screen', '>=laptop') {
	 		padding: 0 45px;
	 	}

	 	@include media('screen', '>=widescreen') {
	 		padding: 0 75px;
	 	}

	 	&.rows-gallery-alt {
	 		margin-top: 30px;

	 		@include media('screen', '>=laptop') {
	 			margin-top: 70px;
	 		}
	 	}

	 	.slick-slide {
	 		padding: 0 15px;
	 	}

		.slick-arrow {
			top: 50%;
			transform: translateY(-50%);

			&.slick-prev {
				left: -20px;

				@include media('screen', '>=widescreen') {
					left: -50px;
				}
			}

			&.slick-next {
				right: -20px;

				@include media('screen', '>=widescreen') {
					right: -50px;
				}
			}
		}

		.slick-dots {
			margin-top: 20px;

			@include media('screen', '>=widescreen') {
				margin-top: 40px;
			}
		}
	 }

	.img-slide {
		min-height: 300px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 10px;
		overflow: hidden;

		@include media('screen', '>=widescreen') {
			min-height: 410px;
		}
	}

	.serv-slide {

		@include media('screen', '>=desktop') {
			padding: 0 20px 0 0;
		}
	 }
}

.accreditation {

	&-box {
		padding-bottom: 50%;
		color: $secondary;

		@include media('screen', '>=desktop') {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding-bottom: 250px;
		}

		@include media('screen', '>=widescreen') {
			padding-bottom: 380px;

		}

		&-column {
			padding: 0 0 40px;

			@include media('screen', '>=desktop') {
				flex: 0 0 45%;
				max-width: 45%;
				padding: 0 15px 30px;
			}

			@include media('screen', '>=laptop') {
				padding: 0 45px 30px;
			}

			+ .accreditation-box-column {
				text-align: center;
				padding-top: 50px;

				@include media('screen', '>=desktop') {
					text-align: right;
					padding-top: 0;
				}
			}
		}

		&-smaller {
			position: relative;
			background: $brand-color;
			color: $white;
			border-radius: 4px;
			margin: -40% 25px 0;

			@include media('screen', '>=desktop') {
				max-width: 80%;
				display: flex;
				justify-content: space-between;
				margin: -230px auto 60px;
			}

			@include media('screen', '>=laptop') {
				margin-bottom: 115px;
			}

			@include media('screen', '>=widescreen') {
				max-width: 1140px;
			}

			.accreditation-box-column {
				padding: 35px 24px;

				@include media('screen', '>=laptop') {
					padding: 66px 30px 66px 66px;
					flex: 0 0 50%;
					max-width: 50%;
				}
			}

			h2, .h2,
			.link {
				color: currentColor;
			}
		}

		&-illustration {
			position: relative;
			//background: url(../images/pattern01.jpg) no-repeat;
			//background-size: cover;
			border-radius: 4px 4px 0 0;

			@include media('screen', '>=desktop') {
				order: 2;
				flex: 0 0 50%;
				max-width: 50%;
				border-radius: 0 4px 4px 0;
			}

      .accreditation-pattern {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        max-height: 400px;

        @include media('screen', '>desktop') {
          max-height: 100%;
        }

        img {
          margin: 0;
          width: 100%;
        }
      }

      .accreditation-box-hero {
        position: absolute;
        left: 15px;
        bottom: 0;
      }

			img {
				display: block;
			}
		}
	}
}

.section-img-gallery {
	position: relative;
	margin: 0 0 50px;

		@include media('screen', '>=widescreen') {
		margin: 0;
	}

	.container {

		@include media('screen', '>=desktop') {
			padding-left: 30px;
			padding-right: 30px;
		}

		@include media('screen', '>=laptop') {
			padding-left: 60px;
			padding-right: 60px;
		}
	}

	.img-gallery {
		position: static;

		&-decors {
			flex-shrink: 0;
			position: absolute;
			bottom: -20px;
			right: 0;
			width: 50%;

			@include media('screen', '>=desktop') {
				width: auto;
			}
		}

		&-decor {
			position: absolute;
			right: 0;

			&01 {
				bottom: 0;
			}

			&02 {
				bottom: 60px;

				@include media('screen', '>=desktop') {
					bottom: 110px;
				}
			}

			&03 {
				bottom: 120px;

				@include media('screen', '>=desktop') {
					bottom: 220px;
				}
			}

			&02,
			&03 {
				z-index: -1;
			}
		}
	}
}

.leadership {
	padding: 0 15px 40px;

	@include media('screen', '>=desktop') {
		padding: 0 30px 87px;
	}

	h2, .h2 {
		flex-shrink: 0;
		width: 100%;

		@include media('screen', '>=desktop') {
			margin: 0 0 50px;
		}
	}

	&-blockquote {
		display: flex;
		flex-wrap: wrap;

		@include media('screen', '>=desktop') {
			justify-content: space-between;
			align-items: flex-end;
			padding-left: 35px;
		}

		@include media('screen', '>=laptop') {
			padding-left: 70px;
		}
	}

	&-text-holder {
		order: 2;

		@include media('screen', '>=desktop') {
			flex: 0 0 48.4%;
			max-width: 48.4%;
			order: 0;
		}

		@include media('screen', '>=laptop') {
			padding: 0 0 40px;
		}

		@include media('screen', '>=widescreen') {
			padding: 0 0 90px;
		}

		h3, .h3 {
			color: $brand-color;
			margin: 0;
		}

		.position {
			font-weight: $font-weight-black;
			color: $secondary;

			@include media('screen', '>=desktop') {
				font-size: 2.2rem;
			}
		}
	}

	&-quote-wrap {
		padding: 20px 20px 0 30px;

		@include media('screen', '>=laptop') {
			padding: 42px 50px 0 65px;
		}

		blockquote {
			padding: 72px 0 10px;
			margin: 0;

			@include media('screen', '>=desktop') {
				font-size: 18px;
				padding-bottom: 2px;
			}

			@include media('screen', '>=laptop') {
				font-size: 21px;
			}

			&:after {

				@include media('screen', '>=desktop') {
					right: 40px;
				}
			}

			q {
				quotes: none;
				display: block;
				margin-bottom: 40px;
				position: relative;
			}
		}

		.sign {
			display: block;
			padding-top: 25px;

			@include media('screen', '>=desktop') {
				padding-top: 50px;
			}

			svg {
				display: block;
				width: 268px;
				height: 68px;

				g {
				  stroke-width: 3;
				  stroke-linecap: round;
	  			  stroke-linejoin: round;
	  			  stroke: #a51641;

					path {
					  fill: transparent;
					  stroke-dasharray:  1000;

						.in-viewport & {
							animation: path_fill 3s linear, fill 1s linear 2s forwards;
						}
					}
				}
			}
		}
	}

	&-img-holder {
		width: 100%;
		position: relative;
		height: 300px;
		max-width: 372px;
		margin: 45px auto 50px;

		@include media('>=phone-lg') {
			margin-top: 95px;
			max-width: 400px;
		}

		@include media('>=tablet') {
			max-width: 600px;
			height: 480px;
		}

		@include media('screen', '>=desktop') {
			flex: 0 0 51.6%;
			max-width: 51.6%;
			height: 380px;
			margin: 0;
		}

		@include media('screen', '>=laptop') {
			height: 430px;
		}

		@include media('screen', '>=widescreen') {
			height: 565px;
			margin: -100px 0 0;
		}

		.map {
			width: 100%;
			height: 100%;
			border-radius: 16px;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.photo {
			position: absolute;
			left: 0;
			bottom: 0;

			img {
				display: block;
				position: relative;
				z-index: 1;
				border-radius: 0 0 16px 16px;
			}
		}
	}
}
