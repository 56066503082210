// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component.
// -----------------------------------------------------------------------------

.js-tab-hidden {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
}

.tab-content .js-tab-hidden {
    left: 0 !important;
    right: 0 !important;
    visibility: hidden;
}

.tabset {
    @extend .listreset;
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 1.1;
    font-weight: $font-weight-black;
    text-align: center;

    @include media('>=desktop') {
        font-size: 2.2rem;
    }

    > li {

        @include media('>=desktop') {
            padding: 0 5px;
        }

        > a {
            display: block;
            border-radius: 4px;
            padding: 6px 8px;

            @include media('>=desktop') {
                padding: 11px 24px;
            }

            &:hover,
            &.active {
                background: $gray-100;
                color: $link-hover-color;
                text-decoration: none;
            }
        }
    }
}