.pagination {
    &-list {
        @extend %listreset;

        li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            &.active {
                a {
                    background-color: $primary;
                    color: $white;
                }
            }
        }

        a {
            display: block;
            padding: 11px 16px;
            font-size: 22px;
            line-height: 1;
            font-weight: 900;
            color: $body-color;
            border-radius: 2px;

            &:hover {
                color: $primary;
                text-decoration: none;
            }
        }
    }
}