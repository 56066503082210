.side-nav {
    @include transition(opacity visibility transform);
    @extend %listreset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: calc(100% - 70px);
    max-width: 342px;
    max-height: 100vh;
    overflow-y: auto;
    touch-action: pan-y;
    padding: 30px 26px;
    margin-bottom: 30px;
    background-color: $gray-100;
    border-radius: 0 0 16px;
    box-shadow: 0 0 5px rgba($black, .3);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);

    @include media('screen', '>=desktop') {
        position: static;
        padding: 0;
        max-width: none;
        width: 100%;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    .side-nav-active & {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }

    > li {
        padding-top: 9px;
        padding-bottom: 9px;

        @include media('screen', '>=desktop') {
            padding-top: 20px;
            padding-bottom: 13px;
            border-top: 4px solid $gray-100;
        }

        @include media('screen', '>=widescreen') {
            padding-right: 45px;
        }

        > a {
            font-size: 24px;
            font-weight: 900;
        }
    }

    ul {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 20px;

        @include media('screen', '>=desktop') {
            padding-top: 0;
            padding-bottom: 0;
        }

        li {
            padding-top: 3px;
            padding-bottom: 3px;

            @include media('screen', '>=desktop') {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }

        ul {
            font-size: 14px;
        }

        .has-drop-down-a {
            display: inline-flex;
        }

        .plus {    
            &:before,
            &:after {
                height: 2px;
            }
        }
    }

    ul {
        @extend %listreset-custom;
        @extend %listreset;
    }

    a {
        position: relative;
        display: block;
    }

    &-opener {
        @include transition(background-color);
        position: absolute;
        top: 70px;
        right: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: $gray-100;

        @include media('screen', '>=desktop') {
            display: none;
        }

        .side-nav-active & {
            background-color: $primary;

            span {
                transform: rotate(45deg);
                color: $white;
            }
            
        }
        
        span {
            @include transition(transform);
            position: relative;
            width: 20px;
            height: 5px;
            color: #555755;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: currentColor;
                content: '';
            }

            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &-title {
        display: none;
        margin-bottom: 24px;

        @include media('screen', '>=desktop') {
            display: block;
        }
    }

    .has-drop-down {
        &.active {
            > .has-drop-down-a {
                .plus {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .has-drop-down-a {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .plus {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 11px;
        height: 11px;
        margin-left: 15px;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 4px;
            background-color: currentColor;
            content: '';
        }

        &:after {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
}