.content {
    margin-bottom: 40px;

    @include media('screen', '>=desktop') {
        float: right;
        width: calc(70% - 30px);
        margin-bottom: 0;
    }

    @include media('screen', '>=widescreen') {
        width: 70.6%;
    }
}

.sidebar {
    @include media('screen', '>=desktop') {
        float: left;
        width: calc(30% - 30px);
    }

    @include media('screen', '>=widescreen') {
        width: 20.9%;
    }
}