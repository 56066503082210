// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------

.resize-active {
   *,
   *:after,
   *:before {
         transition: none !important;
   }
}

.header {
   position: relative;
   z-index: 9999;
   width: 100%;
   min-height: 70px;

   &-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .fixed-position & {
         background: $body-bg;
         padding-bottom: 10px;
         box-shadow: 0 3px 2px rgba($black, .3);
      }
   }

   a {
      @include animate(color);
      text-decoration: none;
      color: currentColor;
      
      &:hover {
         color: $primary;
      }
   }

   .container-fluid {
      @include animate(padding);
      display: flex;
      align-items: center;
      padding: 0 80px 135px 0;
      width: 100%;

      @include media('screen', '>=desktop') {
         padding: 0 0 40px;
      }

      .fixed-position & {

         @include media('screen', '>=desktop') {
            padding: 0 0 10px;
         }
      }
   }

   .logo {
      @include animate(width bottom);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 86px;
      
      @include media('screen', '>=desktop') {
         left: 50px;
         transform: none;
      }

      @include media('screen', '>=laptop') {
         left: 62px;
      }

      @include media('screen', '>=widescreen') {
         width: auto;
         bottom: -140px;
      }

      .fixed-position & {

         @include media('screen', '>=desktop') {
            width: 60px;
            bottom: 10px;
         }
      }

      img {
         display: block;
         width: 100%;
      }
   }

   .info {
      @include animate(width);
      display: block;
      margin: 0 14px 0 0;

      @include media('<tablet') {
         @include size(47px, 70px);
      }

      @include media('>=tablet') {
         margin: 0 19px 0 0;
      }

      .fixed-position & {

         @include media('screen', '>=desktop') {
            width: 45px;
         }
      }

      img {
         display: block;
         width: 100%;
      }
   }

   .guidelines {
      font-size: 1.6rem;
      line-height: 1.2;
      font-weight: $font-weight-medium;
      color: $brand-color-dark;
      max-width: 70%;

      @include media('>=tablet') {
         max-width: none;
         color: $secondary;
      }

      a {
         display: block;
         font-size: 1.5rem;
         font-weight: $font-weight-bold;

         &:hover {
            text-decoration: underline;
         }

         @include media('>=tablet') {
            display: inline-block;
            position: relative;

            &:before {
               content: '//';
               display: inline-block;
               margin: 0 6px;
            }
         }

         @include media('screen', '>=widescreen') {

            &:before {
             margin: 0 18px;  
            }
         }
      }
   }
}

.main-navigation-holder {
   
   @include media('screen', '>=desktop') {
      flex-grow: 1;
   }
}

@include media('<desktop') {

   .wrapper-inner {
      width:100%;
   }

   .burger-menu.burger-menu {
      position: absolute;
      top: 22px;
      right: 18px;
   }

   .nav-active {
      overflow: hidden;

      .burger-menu.burger-menu {
         display: none;
      }
   }

   .mobile-nav-opener {
      @include size(43px);
      background: none;
      border: none;
      text-indent: -9999px;
      overflow: hidden;
      padding: 0;
      position: relative;
      
      flex-shrink: 0;

      @include media('screen', '>=desktop') {
          display: none !important;
      }

      .nav-active &,
      .drop-active & {

         span {
            right: 0;
            top: 18px;
            width: 100%;
            transform: rotate(45deg);

            &::after {
               opacity: 0;
            }

            &::before {
               right: 0;
               top: 0;
               width: 100%;
               height: 5px;
               border-radius: 0;
               transform: rotate(-90deg);
            }
         }
      }

      span {
         transition: all 0.2s;
         position: absolute;
         right: 0;
         width: 100%;
         height: 5px;
         top: 0;
         background: $brand-color-dark;

         &::before,
         &::after {
            transition: all 0.2s;
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
            height: 5px;
            top: 9px;
            background: $brand-color-dark;
         }

         &::before {
            top: 18px;
         }
      }
   }
}

@include media('screen', '>=desktop') {

   .mobile-nav-opener {
      display: none;
   }
}

.drop-holder {
   @include media('<desktop') {
      @include animate(right);
      position: absolute;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100vh;
      display: flex !important;
      flex-direction: column;
      align-items: stretch;
      color: $white;
      background: rgba($gray-100, .98);
   }

   .drop-active & {

      @include media('<desktop') {
         right: 0;
      }
   }

   &.inner-drop-open {
      > .drop-top,
      > .drop-body {
         @include media('<desktop') {
            margin-left: -100%;
         }
      }

      .drop-body .drop-body {
         max-height: calc(100% - 90px);

         @include media('>=phone') {
            max-height: calc(100% - 150px);
         }

         @include media('<desktop') {
            overflow: hidden;
            background: $primary;
            color: $white;
            padding: 25px;
         }

         @include media('screen', '>=desktop') {
            max-height: none;
         }
      }
   }

   ul {
      @extend .listreset;
   }
}

.main-navigation {

   @include media('<desktop') {
      overflow: hidden;
   }

   &.drop-holder {

      .nav-active & {

         @include media('<desktop') {
            right: 0;
         }
      }

      .drop-holder {

         @include media('<desktop') {
            height: calc(100vh - 180px);
         }
      }
   }

   .open-close {

      .slide {
         position: absolute;
         top: 100%;
         left: 0;
         z-index: 2;
         width: 100%;
         padding: 25px;
         background: $gray-100;

         @include media('screen', '>=desktop') {
            background: $brand-color-dark;
            border-radius: 0 0 0 8px;
         }
      }
   }

   .drop-close {
      position: absolute;
      bottom: -55px;
      right: 26px;
      font: 700 1.5rem/1.2 $font-family-base;
      text-transform: uppercase;
      letter-spacing: .7px;
   }
}

.drop-top {
   padding: 22px 26px;
   width: 100%;
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   z-index: 2;
   
   @include media('screen', '>=desktop') {
      display: none;
   }
}

.drop-body {

   @include media('<desktop') {
      @include animate(margin);
      padding: 0 25px;
      flex: 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100% - 90px);
      width: 100%;
      color: $navigation-color;

      .drop-title {
         display: inline-block;
         margin-bottom: 5rem;
         padding: 0 60px 0 0;

         &:hover {
            color: $brand-color-dark;
         }
      }
   }

   @include media('screen', '>=desktop') {
      display: flex;
      width: 100%;

      .drop-title {
         display: none;
      }
   }

   .menu-item {
      
      @include media('<desktop') {
         margin: 0 0 5rem;
         
         &.current-menu-item,
         // &.hover,
         &.drop-active {
            color: $brand-color-dark;
         }
      }
   }
}

.main-menu.main-menu {
   @extend .listreset;
   margin-bottom: 8.6rem;

   @include media('screen', '>=desktop') {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      width: 100%;
   }

   @include media('screen', '>=widescreen') {
      margin: 0 30px 0 0;
   }
   
   a {
      // @include animate(color background);
      text-decoration: none;
      color: currentColor;
   }

   > li {
      font: 4.5rem/1.1 $font-family-sans-serif-secondary;
      letter-spacing: .4px;
      text-transform: capitalize;
      @include animate(color background);

      @include media('screen', '>=desktop') {
         font-size: 2rem;
         letter-spacing: .3px;
         border-radius: 4px;
         // padding-right: 30px;
      }

      @include media('screen', '>=laptop') {
         font-size: 2.4rem;
         margin: 0 5px;
      }

      @include media('screen', '>=widescreen') {
         font-size: 3rem;
      }
      
      &.current-menu-item,
      // &.hover,
      &:hover,
      &.drop-active,
      &.active {
         
         @include media('screen', '>=desktop') {
            color: $primary;
            background: $gray-100;
         }
      }

      &.drop-active {

         @include media('screen', '>=desktop') {

            .opener-menu-item {
               transform: rotate(-180deg);
            }
         }
      }

      &.menu-item-has-children {

         @include media('screen', '>=desktop') {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
         }

         > a {
            position: relative;
            display: block;

            @include media('screen', '>=desktop') {
               padding: 10px 0 12px 10px;
            }
         }

         .opener-menu-item {
            display: none;

            @include media('screen', '>=desktop') {
               @include animate(transform);
               display: block;
               font-size: .5em;
               padding: 0 7px;
               cursor: pointer;
            }
         }
      }

      > a {

         @include media('screen', '>=desktop') {
            display: block;
            white-space: nowrap;
            padding: 10px 10px 12px;
         }
      }
   }

   li {
      
      // &:hover,
      // &.hover, 
      &.drop-active {
         
         > .drop-holder {

            @include media('screen', '>=desktop') {
               visibility: visible;
               max-height: 1000px;
               opacity: 1;
               max-height: calc(100vh - 130px) !important;
               overflow-y: auto;

               .fixed-position & {
                  max-height: calc(100vh - 130px) !important;
                  overflow: auto;
               }
            }
         }
      }
   }

   .drop-holder {

      @include media('screen', '>=desktop') {
         @include animate(max-height visibility opacity);
         position: absolute;
         visibility: hidden;
         max-height: 0;
         opacity: 0;
         top: 98.5%;
         left: 0;
         width: 100%;
         background-color: $gray-100;
         padding: 40px 30px;

         .fixed-position & {
            top: 90.5%;
         }
      }

      @include media('screen', '>=widescreen') {
         padding: 80px 66px;
      }
   }

   .sub-menu {
      height: calc(100% - 100px);
      overflow: auto;
      padding: 0 50px;

      @include media('screen', '>=desktop') {
         height: auto;
         overflow: hidden;
         padding: 0;
         width: calc(100% - 28.5%);
         display: flex;
         flex-wrap: wrap;
      }

      > li {
         font: 900 2.4rem/1.1 $font-family-base;
         
         @include media('screen', '>=desktop') {
            padding: 0 40px 66px 0;
            flex: 0 0 33.3%;
            max-width: 33.3%;
            color: $secondary;
         }

         &.col-lg {

            @include media('screen', '>=desktop') {
               flex: 0 0 66.7%;
               max-width: 66.7%;
            }
         }
         
         &.current-menu-item,
         &.hover,
         &:hover {

            > a {
               color: $brand-color-dark;

               @include media('screen', '>=desktop') {
                  color: $link-hover-color;
                  text-decoration: underline;
               }
            }
         }
         
         // &:hover,
         // &.hover,
         &.drop-active {
            
            > .drop-holder {
               @include media('screen', '>=desktop') {
                  visibility: visible;
                  max-height: 1000px;
               }

               &.drop-hover {
                  @include media('screen', '>=desktop') {
                     overflow: visible !important;
                  }
               }
            }
         }

         > a {
            color: currentColor;
            
            @include media('screen', '>=desktop') {
               display: block;
            }
         }
      }
   }

   .sub-inner-menu {
      margin: 20px 0 0;

      @include media('screen', '>=desktop') {
         margin: 32px 0 0;
      }

      > li {
         font: 2.1rem/1.1 $font-family-sans-serif-tertiary;
         letter-spacing: .3px;
         margin: 0 0 18px;

         @include media('screen', '>=desktop') {
            margin: 0 0 14px;
         }

         &.current-menu-item,
         &.hover,
         &:hover {

            > a,
            > a:hover {
               color: $brand-color-dark;

               @include media('screen', '>=desktop') {
                  color: $link-hover-color;
                  text-decoration: underline;
               }
            }
         }
      }
   }

   .info-title {

      &:hover {
         color: $primary;
         text-decoration: underline;
      }
   }
}

.sub-menu {
   display: none;

   .drop-body > & {
      display: block;
   }
}

.info-drop {
   display: none;

   @include media('screen', '>=desktop') {
      display: block;
      flex: 0 0 34%;
      max-width: 390px;
      padding: 0 80px 0 0;
      color: $brand-color-dark;
   }

   @include media('screen', '>=widescreen') {
      padding: 0 150px 0 0;
      flex: 0 0 39.7%;
      max-width: 540px;
   }

   .h4, .h4 {

      @include media('screen', '>=desktop') {
         display: block;
         font: $font-weight-black 2.2rem/1.1 $font-family-sans-serif;
         margin: 0 0 32px;
      }
   }

   .illustration {

      @include media('screen', '>=desktop') {
         border-radius: 10px;
         margin: 0 0 48px;
         position: relative;
         overflow: hidden;
         height: 307px;

         img {
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
            height: 100%;
            // max-width: none;
         }
      }

      .v-link {

         @include media('screen', '>=desktop') {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 84px;
            border-radius: 10px 0 0 10px;
            background: $primary;
            color: $white;
            padding: 25px 20px 10px;

            &:hover {
               background: $secondary;
            }

            .vertical-title {
               color: currentColor;
               letter-spacing: 1.15px;
            }
         }
      }
   }

   .text-block {

      @include media('screen', '>=desktop') {
         font: 2.1rem/1.1 $font-family-sans-serif-tertiary;
      }

      .info-title {

         @include media('screen', '>=desktop') {
            display: block;
            font: $font-weight-black 2.2rem/1.1 $font-family-sans-serif;
            letter-spacing: .5px;
            margin: 0 0 24px;
         }
      }
   }
}

.add-nav-area {
   @include animate(padding);
   background: $brand-color-dark;
   border-radius: 16px 16px 0 0;
   padding: 20px 15px 4px;
   margin: 0 25px;
   color: $white;

   @include media('>=phone') {
      padding: 32px 33px 4px;
   }

   @include media('screen', '>=desktop') {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding: 20px 15px;
      border-radius: 0 0 0 8px;
      margin: 0;
   }

   .fixed-position & {

      @include media('screen', '>=desktop') {
         padding: 10px;
      }

      .btn {

         @include media('screen', '>=desktop') {
            padding: 6px 18px;
         }
      }

      .opener-search {

         @include media('screen', '>=desktop') {
            width: 25px;
         }
      }
   }

   .open-close {
      display: none;

      @include media('screen', '>=desktop') {
         display: block;
         margin: 0 10px;
      }

      @include media('screen', '>=widescreen') {
         margin: 0 30px 0 25px;
      }
   }

   .slide {

      @include media('screen', '>=desktop') {
         margin-top: -10px;
      }
   }

   .add-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 1.8rem;
      font-weight: $font-weight-bold;
      letter-spacing: 0.21px;

      @include media('>=phone') {
         font-size: 2.1rem;
      }

      @include media('screen', '>=desktop') {
         font-size: 1.6rem;
         letter-spacing: 0.1px;
      }

      li {
         flex: 0 0 50%;
         max-width: 50%;
         order: 0;
         margin: 0 0 23px;

         @include media('screen', '>=desktop') {
            flex: 0 1 auto;
            max-width: none;
            margin: 0 10px;
         }

         @include media('screen', '>=widescreen') {
            margin: 0 25px;
         }

         &:nth-child(2) {

            @include media('<desktop') {
               order: 1;
            }
         }

         &:nth-last-of-type(2) {

            @include media('<desktop') {
               order: 3;
               text-align: right;
            }
         }

         &:last-of-type {

            @include media('<desktop') {
               order: 0;
               text-align: right;
            }

            .btn {
               border-radius: 6px;
               color: currentColor;
               font-size: 2rem;

               @include media('<phone') {
                  padding: 8px 10px;
               }

               @include media('screen', '>=desktop') {
                  font-size: 1.6rem;
               }

               &:hover {
                  color: $secondary;
               }
            }
         }

         a:not([class]) {

            &:hover {
               color: $white;
               text-decoration: underline;
            }
         }
      }
   }
}

.form-search {
   width: 100%;
   position: relative;

   input[type='search'] {
      width: 100%;
      border-color: $brand-color-dark;
      padding: 8px 30px 8px 8px;
      font-family: $font-family-base;
      font-weight: $font-weight-normal;

      @include media('>=tablet') {
         padding: 11px 60px 11px 15px;
         font-size: 3rem;
      }
   }

   .drop-body & {
      font-size: 2.1rem;
   }

   .btn-search {
      @include animate(opacity);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      padding: 5px 10px;
      background: none;
      border: none;
      cursor: pointer;

      @include media('<tablet') {
         width: 40px;
         height: 40px;
      }

      &:hover {
         opacity: .5;
      }

      img {
         display: block;
      }
   }
}

.open-close {

   &.active {

      .opener-search {

         .icon-open {
            opacity: 0;
            visibility: hidden;
         }

         .icon-close {
            opacity: 1;
            visibility: visible;
         }
      }
   }

   .opener-search {
      @include animate(opacity);
      display: block;
      position: relative;

      @include media('screen', '>=desktop') {
      
         &:hover {
            opacity: .5;
         }
      }

      .icon-open {
         @include animate(opacity visibility);
         display: block;
      }

      .icon-close {
         @include animate(opacity visibility);
         position: absolute;
         top: 0;
         left: 0;
         opacity: 0;
         visibility: hidden;
         color: $link-color;
         font-size: 40px;
         z-index: 2;

         @include media('screen', '>=desktop') {
            color: $white;
            left: auto;
            right: 0;
            font-size: 25px;
         }
      }
   }
}
