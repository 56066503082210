.breadcrumb {
    @extend %listreset;

    &-item {
        display: inline-block;
        vertical-align: top;
        font-size: 16px;

        &.active {
            color: $primary;
        }

        & + .breadcrumb-item {
            &:before {
                margin-right: 7px;
                color: $secondary;
                letter-spacing: -4px;
                
                content: "/ /";

                @include media('screen', '>=tablet') {
                    letter-spacing: -2px;
                }
            }
        }
    }
}