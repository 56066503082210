.social-bar {
    @extend %listreset;
    margin-left: -4px;
    margin-right: -4px;

    @include media('screen', '>=tablet') {
        margin-left: -2px;
        margin-right: -2px;
    }

    &.social-bar-primary {
        a {
            background-color: $primary;

            &:hover {
                background-color: darken($primary, 10%);
            }
        }

        img {
            filter: brightness(0) invert(1);
        }
    }

    &.social-bar-float {
        position: fixed;
        right: 0;
        top: 100px;
        z-index: 90;

        @include media('screen', '<tablet') {
            display: none;
        }

        @include media('>=desktop') {
            top: 223px;
        }

        li {
            display: block;
            margin-bottom: 11px;
        }

        a {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    li {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 10px;

        @include media('screen', '>=tablet') {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    a {
        display: block;
        padding: 15px;
        width: 60px;
        height: 60px;
        background-color: $white;
        border-radius: 3px;
        transition: background-color .3s ease;

        &:hover {
            background-color: darken($white, 10%);
        }

        @include media('screen', '>=tablet') {
            width: 46px;
            height: 46px;
            padding: 10px;
        }
    }
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}