// stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors

// -----------------------------------------------------------------------------
// This file contains all styles related to the form elements of the site
// -----------------------------------------------------------------------------

input[type='text'],
input[type='email'],
input[type='tel'],
textarea,
select {
  @include transition(border-color);
  // @include placeholder {
  //   font-weight: 700;
  // }
  border: 3px solid $gray-100;
  background-color: $white;
  width: 100%;
  height: 75px;
  min-width: 0;
  padding: 10px 25px;
  font-size: 22px;
  line-height: 1.5;
  color: $secondary;
  border-radius: 10px;
  -webkit-appearance: none;

  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
  }

  @include placeholder {
    color: $body-color;
  }
}

textarea {
  height: 174px;
  resize: none;
}

select {
  background: url(../images/_icon-chevron-down.svg) 97.3% 50% no-repeat;
  background-size: auto;

  &:required {
    &:invalid {
      color: $body-color;
    }
  }
}

option {
  color: $secondary;

  &[value=""][disabled] {
    display: none;
  }
}

.form-group {
  margin-bottom: 27px;
  position: relative;

  &.input-error {

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea,
    select {
      border-color: $primary;
      background-color: rgba($primary, .2);
    }

    .text-error {
      display: block;
    }

    select {
      background-image: url(../images/_icon-chevron-down-error.svg);
    }
  }

  .text-error {
    position: absolute;
    bottom: -24px;
    left: 25px;
    color: $primary;
    font-size: 14px;
    display: none;
  }
}
