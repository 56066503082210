// -----------------------------------------------------------------------------
// This file contains all styles related to the common layout of the site
// -----------------------------------------------------------------------------

body {
  background-color: $body-bg;
}

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}