.program-row-section {
    padding-top: 50px;
    padding-bottom: 50px;

    &-inner {
        @include media('screen', '>=tablet') {
            padding-left: 40px;
            padding-right: 25px;
        }
        
        @include media('screen', '>=widescreen') {
            padding-left: 60px;
        }
    }
}

.program-row-slider {
    padding: 0 15px;

    @include media('screen', '>=widescreen') {
        padding: 0;
    }

    .slick-list {
        margin-left: -15px;
        margin-right: -15px;
    }

    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }

    .slick-dots {
        margin-top: 45px;
    }

    .slick-arrow {
        top: 140px;
        // font-size: 28px;

        @include media('screen', '>=phone-lg') {
            top: 200px;
        }

        @include media('screen', '>=tablet') {
            top: calc(50% - 79px);
            transform: translateY(-50%);
        }

        &.slick-prev {
            left: -10px;

            @include media('screen', '>=widescreen') {
                left: -98px;
            }
        }
    
        &.slick-next {
            right: -10px;

            @include media('screen', '>=widescreen') {
                right: -18px;
            }
        }
    }
}

.program-row {
    @include media('screen', '>=tablet') {
        display: flex;
    }

    &-col-left {
        @include media('screen', '>=tablet') {
            flex: 1 1 49.5%;
            max-width: 49.5%;
        }

        @include media('screen', '>=widescreen') {
            padding-top: 12px;
        }
    }

    &-col-right {
        @include media('screen', '>=tablet') {
            flex: 1 1 50.5%;
            max-width: 50.5%;
            padding-left: 50px;
        }

        @include media('screen', '>=widescreen') {
            padding-left: 113px;
        }
    }

    &-img {
        position: relative;
        display: block;
        height: 300px;
        margin: 0 10px 30px;
        border-radius: 14px;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

        @include media('screen', '>=phone-lg') {
            height: 400px
        }

        @include media('screen', '>=tablet') {
            margin-bottom: 0;
        }

        @include media('screen', '>=widescreen') {
            height: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 1s ease;
        }
    }

    &-title {
        @include media('screen', '>=widescreen') {
            margin: 0 80px 50px 0;
        }
    }

    &-text {
        @include media('screen', '<laptop') {
            display: none;
        }
    }
}