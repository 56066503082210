.fade-up {
  opacity: 0;

  &.in-viewport,
  .in-viewport & {
    animation-name: fade-up;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.fade-left {
  opacity: 0;

  &.in-viewport,
  .in-viewport & {
    animation-name: fade-left;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.fade {
  opacity: 0;

  &.in-viewport,
  .in-viewport & {
    animation-name: fade;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.rotate {
  animation-name: rotate;
  animation-duration: 90s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  90% {
    transform: translateY(0rem);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }

  90% {
    transform: translateX(0rem);
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes path_fill {
  0% {
    fill: transparent;
    stroke-dashoffset: 1000;
  }

  85% {
    stroke-dashoffset: 500;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  0% {
    fill: transparent;
  }

  100% {
    opacity: 1;
    fill: #a51641;
  }
}