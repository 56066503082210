blockquote {
    position: relative;
    margin: 0 0 $paragraph-margin-bottom;
    padding: 72px 12px 0;

    &:before,
    &:after {
        position: absolute;
    }

    &:before {
        top: 0;
        left: 0;
        content: url(../images/quotes.svg);
    }

    &:after {
        bottom: 0;
        right: 0;
        content: url(../images/quotes02.svg);
    }
}

cite {
    display: block;
    padding-top: 11px;
    font-size: 22px;
    font-weight: 900;
    font-style: normal;
    color: $secondary;
}

ul:not([class]) {
    @extend %listreset;
    margin-bottom: $paragraph-margin-bottom;

    li {
        &:before {
            margin-left: 6px;
            margin-right: 6px;
            content: '•';
        }
    }
}

ol:not([class]) {
    @extend %listreset;
    margin-bottom: $paragraph-margin-bottom;
    counter-reset: custom-counter;

    li {
        counter-increment: custom-counter;

        &:before {
            margin-right: 2px;
            margin-left: 7px;
            content: counter(custom-counter) ". ";
        }
    }
}