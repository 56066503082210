.student-type {
  margin: 50px 0;

  @include media('screen', '>=desktop') {
    margin: 138px 0 100px;
  }

  h2 {
    text-align: center;
    margin: 0 0 39px;

    .title-number {
      color: $red;
    }
  }

  .nav-select {
    display: none;
    @extend %listreset;

    @include media('screen', '>=desktop') {
      display: flex;
      justify-content: center;
      margin-bottom: 37px;
    }

    li {
      margin: 0 6px 12px;

      &.active {
        a {
          color: $red;
          background: #e2e2e2;
        }
      }
    }

    a {
      display: block;
      font-size: 22px;
      line-height: 1.3636363636363635;
      font-weight: 900;
      color: $gray-300;
      padding: 10px 21px;
      text-decoration: none;
      letter-spacing: 0.03em;
      border-radius: 4px;
      @include transition(background color);

      &:hover {
        color: $red;
        background: #e2e2e2;
      }
    }
  }

  .nav-select-mobile {
    display: block;
    margin: 0 0 32px;

    @include media('screen', '>=desktop') {
      display: none;
    }
  }
}


.types {
  padding-top: 35px;
  padding-bottom: 35px;

  &-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &-col {
    width: 100%;
    padding: 0 15px;
    position: relative;

    &.__programs {
      margin: 0 0 50px;

      @include media('screen', '>=desktop') {
        margin: 0;
      }
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &:after {
      display: none;
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      width: 4px;
      left: calc(100% - 2px);
      background-color: $gray-100;

      @include media('screen', '>=desktop') {
        display: block;
      }
    }

    @include media('screen', '>=desktop') {
      width: 50%;
      padding: 0 30px 0 60px;
    }

    h3 {
      margin: 0 0 38px;
    }
  }

  &-list {
    list-style-type: none;
    margin: 0 -15px -74px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 0 15px;
      margin: 0 0 36px;
      width: 100%;

      @include media('screen', '>=phone') {
        width: 50%;
        margin: 0 0 74px;
      }
    }

    h4 {
      margin: 0 0 24px;
      color: $brown;
    }

    p {
      margin: 0;
    }
  }
}


.find-program {
  position: relative;
  z-index: 9;
  // margin: 0 25px;

  @include media('screen', '>=desktop') {
    margin-bottom: -364px;
  }

  h2 {
    color: $white;
  }

  .container {
    // max-width: 1170px;
    color: $white;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    background: $brand-color;
    color: $white;
    border-radius: 4px;

    @include media('screen', '>=desktop') {
      max-width: 1140px;
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      margin: 0 auto;
    }
  }

  &-col {
    width: 100%;
    // background-color: $brand-color;

    &.__info {
      padding: 32px 15px;
      // border-radius: 4px 4px 0 0;

      @include media('screen', '>=desktop') {
        width: 46%;
        padding: 77px 19px 90px 72px;
        // border-radius: 4px 0 0 4px;
      }
    }

    &.__decorated {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      overflow: hidden;
      border-radius: 0 0 4px 4px;

      @include media('screen', '>=desktop') {
        border-radius: 0 4px 4px 0;
        width: 54%;
      }

      .find-pattern {
        height: 100%;
        width: 100%;

        max-height: 450px;

        @include media('screen', '>=desktop') {
          max-height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .find-program-image {
        position: absolute;
        z-index: 9;

        img {
          max-height: 400px;
          @include media('screen', '>desktop') {
            max-height: 100%;
          }
        }
        //@include media('screen', '>=tablet') {
        //  margin: 0 0 76px;
        //}

        //@include media('screen', '>=widescreen') {
        //  margin: 0 0 76px;
        //}
      }
    }
  }

  &-image {
    font-size: 0;
    //position: relative;
    position: absolute;
    z-index: 9;
  }
}

.explore-options {
  h2 {
    margin: 0 0 32px;
    text-align: center;

    @include media('screen', '>=desktop') {
      margin: 0 0 76px;
    }

    .title-number {
      color: $red;
    }
  }

  .container {
    padding: 32px 15px;
    position: relative;

    @include media('screen', '>=desktop') {
      padding: 0 75px;
      padding-top: 456px;
      padding-bottom: 137px;
      background: #e2e2e2;
      border-radius: 4px;
    }

    &:before,
    &:after {
      position: absolute;
      height: 3px;
      top: 25px;
      left: 0;
      right: 0;
      background: $white;

      @include media('screen', '>=desktop') {
        content: '';
      }
    }

    &:after {
      top: auto;
      bottom: 25px;
    }
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include media('screen', '>=desktop') {
      margin: 0 -75px;
    }
  }

  &-col {
    margin: 0 0 48px;
    width: 100%;
    padding: 0 15px;

    &:last-of-type {
      margin: 0;
    }

    @include media('screen', '>=tablet') {
      width: 50%;
    }

    @include media('screen', '>=desktop') {
      padding: 0 75px;
    }
  }
}

.apply-today {
  background-color: $brown;
  color: $white;
  padding: 32px 0 0;

  @include media('screen', '>=desktop') {
    padding: 545px 0 388px;
    margin-top: -430px;
    margin-bottom: -320px;
  }
  @include media('screen', '>=laptop') {
    padding: 545px 0 588px;
  }

  h2 {
    color: $white;
  }

  .container {
    max-width: 100%;
    padding: 0;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &-col {
    width: 100%;

    &.__info {
      margin: 0 0 32px;

      @include media('screen', '>=desktop') {
        width: 46%;
        padding: 0;
        margin: 0;
      }
    }

    &.__photo {
      position: relative;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include media('screen', '>=desktop') {
        justify-content: flex-end;
        margin: 0 0 0 -15px;
        width: calc(54% + 15px);
        max-width: calc(54% + 15px);
        width: 54%;
        margin-left: auto;
      }

      .decor-below {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        //background: url(../images/apply-decor-0.png) no-repeat bottom / contain;
        width: 100%;
        height: 593px;
        z-index: -1;

        @include media('screen', '>=desktop') {
          display: block;
        }
      }
      .decor-above {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        //background: url(../images/apply-decor-2.png) no-repeat bottom / contain;
        width: 100%;
        height: 250px;
        z-index: 3;

        @include media('screen', '>=desktop') {
          display: block;
        }
        @include media('screen', '>=laptop') {
          height: 270px;
        }
      }
    }
  }

  &-info {
    padding: 0 15px;

    @include media('screen', '>=desktop') {
      max-width: 525px;
      margin-left: auto;
      padding: 0 0 0 15px;
    }
  }

  &-photo {
    position: relative;
    font-size: 0;
    padding: 0 15px 50px;

    @include media('screen', '>=desktop') {
      padding: 0 56px 70px 100px;
    }

    &-inner {
      border-radius: 12px;
      overflow: hidden;
    }
  }

}

.more-info {
  margin: 50px 0 72px;

  @include media('screen', '>=desktop') {
    margin: 100px 0 72px;
  }

  .contact-form-inner {
    display: block;

    @include media('screen', '>=desktop') {
      margin: 0;
    }
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -15px;
  }

  &-col {
    padding: 0 15px;
    width: 100%;

    &.__form {
      margin: 0 0 50px;

      @include media('screen', '>=desktop') {
        margin: 0;
      }
    }

    @include media('screen', '>=desktop') {
      width: 50%;
    }
  }

  &-map {
    margin: 80px 0 0;

    @include media('screen', '>=desktop') {
      margin: 80px 0 85px;
    }

    .google-map {
      position: relative;
      padding: 0 0 58.333%;
      border-radius: 12px;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

      img,
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.apply-now {
  .container {
    padding: 32px 15px;
    position: relative;

    @include media('screen', '>=desktop') {
      padding: 0 71px;
      padding-top: 100px;
      padding-bottom: 435px;
      background: #e2e2e2;
      border-radius: 4px;
    }

    &:before,
    &:after {
      position: absolute;
      height: 3px;
      top: 25px;
      left: 0;
      right: 0;
      background: $white;

      @include media('screen', '>=desktop') {
        content: '';
      }
    }

    &:after {
      top: auto;
      bottom: 25px;
    }
  }

  h2 {
    margin: 0 0 30px;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include media('screen', '>=desktop') {
      margin: 0 -71px;
    }
  }

  &-col {
    padding: 0 15px;
    width: 100%;
    margin: 0 0 32px;

    @include media('screen', '>=desktop') {
      width: 50%;
      padding: 0 71px;
      margin: 0;
    }

    &:last-of-type {
      margin: 0;
    }
  }
}

.counselor {
  padding: 75px 35px 0 0;
  color: $brown;
  position: relative;

  @include media('screen', '>=tablet') {
    padding: 75px 60px 70px 0;
  }

  @include media('screen', '>=desktop') {
    padding-top: 152px;
  }

  @include media('screen', '>=laptop') {
    padding-right: 0;
  }

  .container {
    padding: 0 32px;

    @include media('screen', '>=desktop') {
      padding: 0 15px;
    }
  }

  .tilted-text {
    position: absolute;
    right: 0;
    top: 40px;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    font-size: 30px;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 400;
    color: $gray-200;
    letter-spacing: 0.03em;
    z-index: 9;

    @include media('screen', '>=tablet') {
      right: 30px;
    }

    @include media('screen', '>=laptop') {
      right: 43px;
      top: -34px;
    }

    span {
      position: absolute;
      white-space: nowrap;
    }

    .chevrons {
      margin-left: 11px;
    }

    .chevrons-item {
      margin-left: 1px;
    }

    .chevrons-item:before {
      transform: rotate(-45deg);
    }
  }

  h2 {
    text-align: center;
    margin: 0 0 52px;

    @include media('screen', '>=desktop') {
      margin: 0 0 92px;
      text-align: center;
    }
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -15px;
  }

  &-col {
    padding: 0 15px;
    width: 100%;

    @include media('screen', '>tablet') {
      width: 50%;
    }

    &.__info {
      margin: 0 0 36px;
      order: 1;

      @include media('screen', '>tablet') {
        margin: 0;
        order: 0;
      }
    }

    &.__photo {
      display: flex;
      justify-content: center;
      margin: 0 0 32px;

      @include media('screen', '>tablet') {
        margin: 0;
      }
    }
  }

  &-title {
    margin: 0 0 36px;

    @include media('screen', '>=desktop') {
      margin: 0 0 76px;
    }

    h3 {
      color: $brown;
      margin: 0 0 21px;
    }

    span {
      font-family: $font-family-sans-serif;
      font-size: 22px;
      font-weight: $font-weight-black;
      letter-spacing: 0.05em;
    }
  }

  &-text {
    padding: 0 0 0 15px;

    @include media('screen', '>=desktop') {
      padding: 0 0 0 70px;
      max-width: 570px;
    }

    p {
      margin: 0 0 52px;
    }
  }

  &-photo {
    font-size: 0;
    border-radius: 28px;
    overflow: hidden;
  }
}

.explore-programs {
  margin: 0 0 50px;

  @include media('screen', '>=desktop') {
    margin: 0 0 112px;
  }

  h2 {
    text-align: center;
    margin: 0 0 32px;

    @include media('screen', '>=desktop') {
      margin: 0 0 61px;
    }
  }

  &-button {
    display: flex;
    justify-content: center;
  }

  .features-list {
    margin: 0 0 35px;

    li {
      @include media('screen', '>=desktop') {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
      }
    }

    a {
      @include media('screen', '>=desktop') {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.counselor+.info-block {
  @include media('screen', '>=desktop') {
    margin-top: -160px;
  }
}

.enroll {
  padding: 50px 0 0;

  @include media('screen', '>=desktop') {
    padding: 0;
  }

  h2 {
    color: $white;
    position: absolute;
    z-index: 9;
    padding: 0 32px;
    margin: 0;
    text-align: center;
    left: 50%;
    top: 100px;
    width: 100%;
    transform: translate(-50%, -50%);

    @include media('screen', '>=desktop') {
      text-align: left;
      margin: 0 0 -138px;
      padding: 0 0 0 70px;
      max-width: 550px;
      left: 15px;
      top: 78px;
      transform: none;
    }
  }

  @include media('screen', '>=desktop') {
    margin-top: -312px;
  }

  .container {
    max-width: 1170px;
    position: relative;
  }

  &-inner {
    background-color: $red;
    color: $white;
    position: relative;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;

    @include media('screen', '>=desktop') {
      border-radius: 10px;
    }

    h3 {
      color: $white;
      letter-spacing: 0.1em;
    }
  }

  &-col {
    width: 100%;

    &.__info {
      padding: 200px 15px 32px;

      @include media('screen', '>=desktop') {
        width: 47%;
        padding: 216px 18px 100px 70px;
        // border-radius: 4px 0 0 4px;

      }
    }

    &.__photo {
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-radius: 0 0 4px 4px;

      @include media('screen', '>=desktop') {
        width: 53%;
        justify-content: flex-start;
        border-radius: 0 10px 10px 0;

      }

      .enroll-pattern {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 125%;

        img {
          max-width: unset;
          width: 100%;
        }
      }
    }
  }

  &-img {
    //position: relative;
    //z-index: 9;
    font-size: 0;
  }

  &-slider {
    width: 100%;

    .slick-dots {
      justify-content: center;
      position: relative;
      z-index: 9;
      margin: 32px 0 0;

      @include media('screen', '>=desktop') {
        padding: 0 0 0 106px;
        justify-content: flex-start;
        max-width: 500px;
        margin: -100px 0 0;
      }
    }

    li.slick-active,
    li a:hover {
      button {
        @include media('screen', '>=desktop') {
          background-color: $white;
        }
      }
    }

    .slick-arrow {
      z-index: 9;
      color: $white;
      transform: none;
      font-size: 32px;
      top: 80px;
      display: none !important;

      @include media('screen', '>=tablet') {
        display: block !important;
      }

      @include media('screen', '>=desktop') {
        top: 50%;
      }

      &.slick-prev {
        left: 8px;

        @include media('screen', '>=tablet') {
          left: 32px;
        }
      }

      &.slick-next {
        right: 8px;

        @include media('screen', '>=tablet') {
          right: 32px;
        }
      }
    }
  }

  &-info {
    max-width: 100%;
    padding: 0 12px 0 0;

    @include media('screen', '>=phone') {
      padding: 0 12px 0 36px;
    }

    p {
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
