// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  @include transition(color background-color border-color);
  display: inline-block;
  vertical-align: middle;
  padding: 12px 18px;
  font-size: 16px;
  line-height: 1.15;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  border: 3px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &-start {
    padding: 10px 44px 10px 17px;
    color: $red;
    border-color: $red;
    letter-spacing: 0.64px;

    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  &-white {
    color: $white;
    border-color: $white;

    &:hover {
      background-color: $white;
      color: $red;
    }

    &.has-arrow {
      position: relative;

      &:hover {
        &:before {
          border-color: $red !important;
        }
      }

      &:before {
        border-color: $white !important;
      }
    }
  }

  &.has-arrow {
    position: relative;
    width: 100%;
    font-size: 23px;
    border-width: 3px;
    border-radius: 8px;
    text-align: left;
    padding: 19px 60px 18px 28px;
    letter-spacing: 0.04em;

    @include media('screen', '>=tablet') {
      width: auto;
      font-size: 16px;
      border-width: 3px;
      border-radius: 3px;
      text-align: center;
      padding: 8px 44px 8px 17px;
    }

    @include media('screen', '>=desktop') {
      text-align: left;
    }

    &:hover {
      &:before {
        border-color: $white;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%) rotate(-135deg);
      height: 18px;
      width: 18px;
      border: solid $red;
      border-width: 0 0 3px 3px;
      @include transition(border-color);

      @include media('screen', '>=tablet') {
        height: 13px;
        width: 13px;
        right: 17px;
      }
    }
  }

  &-mobile-lg {
    @include media('screen', '<phone-lg') {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 17px 26px;
      font-size: 23px;
      line-height: 1.5;
      text-align: left;
      border-radius: 8px;
    }
  }

  .icon-arrow-right {
    margin-left: 8px;
  }
}
